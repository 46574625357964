import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { registerUser, loginUser } from '../services/auth';
import { checkUsernameAvailability } from '../services/userProfile';
import { validateUsername } from '../utils/usernameValidation';
import { validatePassword } from '../utils/passwordValidation';
import { validateEmail } from '../utils/emailValidation';

function Auth() {
  const [identifier, setIdentifier] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isLogin, setIsLogin] = useState(true);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.message) {
      setMessage(location.state.message);
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const handleIdentifierChange = (e) => {
    const newIdentifier = e.target.value;
    setIdentifier(newIdentifier);
    if (newIdentifier === '') {
      setErrors(prev => ({ ...prev, identifier: null }));
    } else if (isLogin) {
      setErrors(prev => ({ ...prev, identifier: null }));
    } else {
      const emailError = validateEmail(newIdentifier);
      setErrors(prev => ({ ...prev, identifier: emailError }));
    }
  };

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value.toLowerCase();
    setUsername(newUsername);
    if (newUsername === '') {
      setErrors(prev => ({ ...prev, username: null }));
    } else {
      const usernameError = validateUsername(newUsername);
      setErrors(prev => ({ ...prev, username: usernameError }));
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (newPassword === '') {
      setErrors(prev => ({ ...prev, password: null }));
    } else if (isLogin) {
      setErrors(prev => ({ ...prev, password: null }));
    } else {
      const passwordError = validatePassword(newPassword);
      setErrors(prev => ({ ...prev, password: passwordError }));
    }
  };

  const handleUsernameBlur = async () => {
    if (!isLogin && username) {
      try {
        const isAvailable = await checkUsernameAvailability(username);
        if (!isAvailable) {
          setErrors(prev => ({ ...prev, username: 'このユーザーネームは既に使用されています。' }));
        }
      } catch (error) {
        console.error('Error checking username availability:', error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLogin) {
      if (!identifier) {
        setErrors(prev => ({ ...prev, identifier: 'メールアドレスまたはユーザーネームを入力してください。' }));
        return;
      }
      if (!password) {
        setErrors(prev => ({ ...prev, password: 'パスワードを入力してください。' }));
        return;
      }
      try {
        await loginUser(identifier, password);
        navigate('/');
      } catch (error) {
        setErrors(prev => ({ ...prev, form: error.message }));
        setPassword('');  // エラー時にパスワードをクリア
      }
    } else {
      const identifierError = validateEmail(identifier);
      const usernameError = validateUsername(username);
      const passwordError = validatePassword(password);

      if (identifierError || usernameError || passwordError) {
        setErrors({
          identifier: identifierError,
          username: usernameError,
          password: passwordError,
        });
        return;
      }
      try {
        const isUsernameAvailable = await checkUsernameAvailability(username);
        if (!isUsernameAvailable) {
          setErrors({ username: 'このユーザーネームは既に使用されています。' });
          return;
        }

        await registerUser(identifier, password, username);
        navigate('/');
      } catch (error) {
        setErrors({ form: error.message });
      }
    }
  };

  const getInputClassName = (fieldName) => {
    const baseClasses = "w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2";
    const value = fieldName === 'identifier' ? identifier : fieldName === 'username' ? username : password;

    if (errors[fieldName]) {
      return `${baseClasses} border-red-500 focus:ring-red-500`;
    } else if (value !== '' && !isLogin) {
      return `${baseClasses} border-green-500 focus:ring-green-500`;
    } else {
      return `${baseClasses} border-gray-300 focus:ring-blue-500`;
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800 dark:text-white">
          {isLogin ? 'ログイン' : '新規登録'}
        </h2>
        {message && (
          <div className="mb-4 p-2 bg-blue-100 border border-blue-400 text-blue-700 rounded">
            {message}
          </div>
        )}
        {errors.form && <p className="text-red-500 mb-4">{errors.form}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="identifier" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
              {isLogin ? 'メールアドレス または ユーザーネーム' : 'メールアドレス'}
            </label>
            <input
              type="text"
              id="identifier"
              value={identifier}
              onChange={handleIdentifierChange}
              className={getInputClassName('identifier')}
              required
            />
            {errors.identifier && identifier !== '' && <p className="mt-1 text-sm text-red-500">{errors.identifier}</p>}
          </div>
          {!isLogin && (
          <div className="mb-4">
            <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300 flex items-center">
              ユーザーネーム
              <span
                className="ml-2 inline-flex items-center justify-center w-5 h-5 text-xs text-gray-600 bg-gray-200 rounded-full cursor-help"
                data-tooltip-id="username-tooltip"
              >
                ?
              </span>
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              onBlur={handleUsernameBlur}
              className={getInputClassName('username')}
              required
            />
            {errors.username && username !== '' && <p className="mt-1 text-sm text-red-500">{errors.username}</p>}
            <Tooltip id="username-tooltip" place="top" effect="solid">
              <div className="max-w-xs p-2">
                <p className="mb-2">ユーザーネームは、あなたを識別する固有の名前です。</p>
                <ul className="list-disc pl-5">
                  <li>プロフィールURLの一部として使用されます</li>
                  <li>3〜20文字の小文字英数字と_(アンダースコア)が使用可能です</li>
                  <li>後から変更することができます</li>
                </ul>
              </div>
            </Tooltip>
          </div>
          )}
          <div className="mb-4">
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
              パスワード
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className={getInputClassName('password')}
              required
            />
            {errors.password && password !== '' && <p className="mt-1 text-sm text-red-500">{errors.password}</p>}
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {isLogin ? 'ログイン' : '登録'}
          </button>
        </form>
        <p className="mt-4 text-center text-sm text-gray-600 dark:text-gray-400">
          {isLogin ? 'アカウントをお持ちでない方は' : 'すでにアカウントをお持ちの方は'}
          <button
            onClick={() => {
              setIsLogin(!isLogin);
              setErrors({});
              setIdentifier('');
              setUsername('');
              setPassword('');
            }}
            className="text-blue-500 hover:underline focus:outline-none ml-1"
          >
            {isLogin ? '新規登録' : 'ログイン'}
          </button>
        </p>
      </div>
    </div>
  );
}

export default Auth;