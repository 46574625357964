import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import AppBar from '../components/AppBar';
import BottomNav from '../components/BottomNav';
import {
  UserIcon,
  EnvelopeIcon,
  LockClosedIcon,
  CalendarIcon,
  TrashIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline';

function AccountInfo() {
  const { currentUser } = useAuth();

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = timestamp.toDate();
    return date.toLocaleDateString('ja-JP', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      // 明示しない場合はブラウザのローカルタイムゾーンになる
      // timeZone: 'Asia/Tokyo'
    });
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <AppBar />
      <main className="flex-grow overflow-y-auto p-4 pb-20">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">アカウント情報</h2>

        <div className="space-y-4">
          <Link
            to="/settings/account/username"
            className="block w-full text-left py-3 px-4 bg-white dark:bg-gray-800 rounded-lg shadow hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-150 ease-in-out"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <UserIcon className="h-6 w-6 text-gray-600 dark:text-gray-400 mr-3" />
                <div>
                  <p className="text-gray-800 dark:text-white font-medium">ユーザー名</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">@{currentUser.username}</p>
                </div>
              </div>
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            </div>
          </Link>

          <Link
            to="/settings/account/email"
            className="block w-full text-left py-3 px-4 bg-white dark:bg-gray-800 rounded-lg shadow hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-150 ease-in-out"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <EnvelopeIcon className="h-6 w-6 text-gray-600 dark:text-gray-400 mr-3" />
                <div>
                  <p className="text-gray-800 dark:text-white font-medium">メールアドレス</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">{currentUser.email}</p>
                </div>
              </div>
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            </div>
          </Link>

          <Link
            to="/settings/account/password"
            className="block w-full text-left py-3 px-4 bg-white dark:bg-gray-800 rounded-lg shadow hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-150 ease-in-out"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <LockClosedIcon className="h-6 w-6 text-gray-600 dark:text-gray-400 mr-3" />
                <p className="text-gray-800 dark:text-white font-medium">パスワードを変更</p>
              </div>
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            </div>
          </Link>

          <div className="w-full text-left py-3 px-4 bg-white dark:bg-gray-800 rounded-lg shadow">
            <div className="flex items-center">
              <CalendarIcon className="h-6 w-6 text-gray-600 dark:text-gray-400 mr-3" />
              <div>
                <p className="text-gray-800 dark:text-white font-medium">アカウント作成日</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">{formatDate(currentUser.createdAt)}</p>
              </div>
            </div>
          </div>

          <div
            className="block w-full text-left py-3 px-4 bg-white dark:bg-gray-800 rounded-lg shadow cursor-not-allowed opacity-50"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <TrashIcon className="h-6 w-6 text-red-500 dark:text-red-400 mr-3" />
                <p className="text-red-500 dark:text-red-400 font-medium">アカウントを削除</p>
              </div>
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>
      </main>
      <BottomNav />
    </div>
  );
}

export default AccountInfo;