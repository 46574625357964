import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getUserProfile } from '../services/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateCurrentUserProfile = async () => {
    if (currentUser) {
      try {
        const userProfile = await getUserProfile(currentUser.uid);
        setCurrentUser(prevUser => ({ ...prevUser, ...userProfile }));
        return userProfile;
      } catch (error) {
        console.error('Error updating user profile:', error);
        throw error;
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          setTimeout(async () => {
            try {
              const userProfile = await getUserProfile(user.uid);
              setCurrentUser({ ...user, ...userProfile });
            } catch (error) {
              console.error('Error fetching user profile:', error);
              setCurrentUser(user);
            }
            setLoading(false);
          }, 1500);
        } catch (error) {
          console.error('Error in auth state change:', error);
          setCurrentUser(null);
          setLoading(false);
        }
      } else {
        setCurrentUser(null);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    loading,
    updateCurrentUserProfile
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}