import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import AppBar from '../components/AppBar';
import BottomNav from '../components/BottomNav';
import Modal from '../components/Modal';
import { logoutUser } from '../services/auth';
import {
  UserCircleIcon,
  InformationCircleIcon,
  MoonIcon,
  SunIcon,
  ChevronRightIcon,
  ArrowRightStartOnRectangleIcon
} from '@heroicons/react/24/outline';

function Settings() {
  const { isDarkMode, toggleTheme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
  };

  const handleLogoutConfirm = async () => {
    try {
      await logoutUser();
      navigate('/auth');
    } catch (error) {
      console.error('Logout failed', error);
      // エラーメッセージを表示するなどの処理を追加
    }
    setIsLogoutModalOpen(false);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <AppBar />
      <main className="flex-grow overflow-y-auto p-4 pb-20">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">設定</h2>

        <div className="space-y-4">
          <Link
            to="/settings/edit-profile"
            state={{ from: location.pathname }}
            className="block w-full text-left py-3 px-4 bg-white dark:bg-gray-800 rounded-lg shadow hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-150 ease-in-out"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <UserCircleIcon className="h-6 w-6 text-gray-600 dark:text-gray-400 mr-3" />
                <div>
                  <p className="text-gray-800 dark:text-white font-medium">プロフィール編集</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">ユーザーアイコン、表示名、自己紹介</p>
                </div>
              </div>
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            </div>
          </Link>

          <Link
            to="/settings/account"
            className="block w-full text-left py-3 px-4 bg-white dark:bg-gray-800 rounded-lg shadow hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-150 ease-in-out"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <InformationCircleIcon className="h-6 w-6 text-gray-600 dark:text-gray-400 mr-3" />
                <div>
                  <p className="text-gray-800 dark:text-white font-medium">アカウント情報</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">ユーザー名、メールアドレス、パスワード</p>
                </div>
              </div>
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
            </div>
          </Link>

          <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden">
            <button
              onClick={toggleTheme}
              className="w-full text-left py-3 px-4 hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {isDarkMode ? (
                    <MoonIcon className="h-6 w-6 text-gray-600 dark:text-gray-400 mr-3" />
                  ) : (
                    <SunIcon className="h-6 w-6 text-gray-600 dark:text-gray-400 mr-3" />
                  )}
                  <p className="text-gray-800 dark:text-white font-medium">ダークモード</p>
                </div>
                <div className={`w-11 h-6 ${
                  isDarkMode ? "bg-blue-600" : "bg-gray-300"
                } rounded-full p-1 duration-300 ease-in-out`}>
                  <div className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                    isDarkMode ? "translate-x-5" : "translate-x-0"
                  }`} />
                </div>
              </div>
            </button>
          </div>

          <button
            onClick={handleLogoutClick}
            className="w-full text-left py-3 px-4 bg-white dark:bg-gray-800 rounded-lg shadow hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-150 ease-in-out"
          >
            <div className="flex items-center">
              <ArrowRightStartOnRectangleIcon className="h-6 w-6 text-red-500 dark:text-red-400 mr-3" />
              <p className="text-red-500 dark:text-red-400 font-medium">ログアウト</p>
            </div>
          </button>
        </div>
      </main>
      <BottomNav />

      <Modal
        isOpen={isLogoutModalOpen}
        onClose={() => setIsLogoutModalOpen(false)}
        onConfirm={handleLogoutConfirm}
        title="ログアウトしますか？"
        message="ログアウトすると、お気に入りの更新やフォロー中のユーザーの活動が見られなくなります。再度ログインすれば、いつでも戻ってこられます。"
        confirmText="ログアウト"
        isShowTitle={true}
        messageSize="small"
        modalWidth="narrow"
        confirmColor="red"
        messageAlign="left"
      />
    </div>
  );
}

export default Settings;