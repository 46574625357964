export const validateEmail = (email) => {
  // 基本的なメールアドレスの形式をチェックする正規表現
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (!email) {
    return 'メールアドレスを入力してください。';
  }

  if (!emailRegex.test(email)) {
    return '有効なメールアドレスを入力してください。';
  }

  // メールアドレスのドメイン部分を取得
  const domain = email.split('@')[1];

  // よく間違えられるドメインをチェック
  const commonTypos = {
    // Gmailの一般的な誤タイプ
    'gmial.com': 'gmail.com',
    'gamil.com': 'gmail.com',
    'gmai.com': 'gmail.com',
    'gmal.com': 'gmail.com',
    'gmale.com': 'gmail.com',
    'gmil.com': 'gmail.com',
    'gmaill.com': 'gmail.com',
    'gnail.com': 'gmail.com',
    'mail.com': 'gmail.com',

    // Yahooの一般的な誤タイプ
    'yhoo.com': 'yahoo.com',
    'yaho.com': 'yahoo.com',
    'yahooo.com': 'yahoo.com',
    'yahou.com': 'yahoo.com',
    'yayoo.com': 'yahoo.com',
    'yhaoo.com': 'yahoo.com',
    'tahoo.com': 'yahoo.com',
    'yaoo.com': 'yahoo.com',

    // Hotmailの一般的な誤タイプ
    'hotmal.com': 'hotmail.com',
    'hotmale.com': 'hotmail.com',
    'hotmail.co': 'hotmail.com',
    'hotmial.com': 'hotmail.com',
    'hotmall.com': 'hotmail.com',
    'hatmail.com': 'hotmail.com',

    // Outlookの一般的な誤タイプ
    'outloo.com': 'outlook.com',
    'outlook.co': 'outlook.com',
    'outlok.com': 'outlook.com',
    'oultook.com': 'outlook.com',
    'outook.com': 'outlook.com',

    // iCloudの一般的な誤タイプ
    'icloud.co': 'icloud.com',
    'iclod.com': 'icloud.com',
    'icloude.com': 'icloud.com',
  };

  if (commonTypos[domain]) {
    return `もしかして: ${email.split('@')[0]}@${commonTypos[domain]} ではありませんか？`;
  }

  return null; // メールアドレスが有効な場合
};