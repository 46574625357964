import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { updateUserThemePreference } from '../services/auth';

const ThemeContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser.isDarkMode !== undefined) {
      setIsDarkMode(currentUser.isDarkMode);
    }
  }, [currentUser]);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleTheme = async () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    if (currentUser) {
      try {
        await updateUserThemePreference(currentUser.uid, newMode);
      } catch (error) {
        console.error('Failed to update theme preference:', error);
      }
    }
  };

  const value = {
    isDarkMode,
    toggleTheme
  };

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
}