import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import AppBar from '../components/AppBar';
import BottomNav from '../components/BottomNav';
import LoadingComponent from '../components/LoadingComponent';
import FollowButton from '../components/FollowButton';
import { getFollowingUsers, getFollowers, getUserByUsername } from '../services/firestore';
import { useAuth } from '../contexts/AuthContext';

function FollowPage() {
  const { username, type } = useParams();
  const [activeTab, setActiveTab] = useState(type || 'following');
  const [followingUsers, setFollowingUsers] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const user = await getUserByUsername(username);
        if (!user) {
          navigate('/404');
          return;
        }
        const [fetchedFollowing, fetchedFollowers] = await Promise.all([
          getFollowingUsers(user.id, currentUser.uid),
          getFollowers(user.id, currentUser.uid)
        ]);
        setFollowingUsers(fetchedFollowing);
        setFollowers(fetchedFollowers);
      } catch (error) {
        console.error('Error fetching users:', error);
        // エラー処理を追加する（例：エラーメッセージの表示）
      }
      setLoading(false);
    };

    fetchUsers();
  }, [username, navigate, currentUser.uid]);

  const handleFollowChange = (userId, isFollowing) => {
    const updateUsers = (users) => users.map(u =>
      u.id === userId ? { ...u, isFollowing } : u
    );
    setFollowingUsers(updateUsers);
    setFollowers(updateUsers);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  const displayUsers = activeTab === 'following' ? followingUsers : followers;

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <AppBar />
      <main className="flex-grow overflow-y-auto pb-20">
        <div className="bg-white dark:bg-gray-800 shadow">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">{username}</h1>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
          <div className="flex mb-4 border-b border-gray-200 dark:border-gray-700">
            <button
              onClick={() => setActiveTab('following')}
              className={`flex-1 py-2 px-4 text-center ${
                activeTab === 'following' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500 dark:text-gray-400'
              }`}
            >
              フォロー中 ({followingUsers.length})
            </button>
            <button
              onClick={() => setActiveTab('followers')}
              className={`flex-1 py-2 px-4 text-center ${
                activeTab === 'followers' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500 dark:text-gray-400'
              }`}
            >
              フォロワー ({followers.length})
            </button>
          </div>

          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            {displayUsers.map(user => (
              <li key={user.id} className="py-4">
                <div className="flex items-center space-x-4">
                  <Link to={`/${user.username}`} className="flex-shrink-0">
                    <img src={user.avatar} alt={user.displayName} className="w-12 h-12 rounded-full" />
                  </Link>
                  <div className="flex-grow min-w-0">
                    <Link to={`/${user.username}`} className="block">
                      <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                        {user.displayName}
                      </p>
                      <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                        @{user.username}
                      </p>
                    </Link>
                    {user.bio && (
                      <p className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2">
                        {user.bio}
                      </p>
                    )}
                  </div>
                  {user.id !== currentUser.uid && (
                    <div className="flex-shrink-0">
                      <FollowButton
                        userId={user.id}
                        userName={user.displayName}
                        size="small"
                        shape="rounded-full"
                        initialIsFollowing={user.isFollowing}
                        onFollowChange={(isFollowing) => handleFollowChange(user.id, isFollowing)}
                      />
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </main>
      <BottomNav />
    </div>
  );
}

export default FollowPage;