import React from 'react';
import { useNavigate } from 'react-router-dom';

function GuestCTA() {
  const navigate = useNavigate();

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 shadow-lg py-3 px-4">
      <div className="max-w-screen-xl mx-auto flex justify-between items-center">
        <p className="text-sm sm:text-base text-gray-700 dark:text-gray-300">
          あなたの隠れた"好き"を発見しよう！
        </p>
        <button
          onClick={() => navigate('/auth')}
          className="bg-blue-500 hover:bg-blue-600 text-white text-sm sm:text-base font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
        >
          はじめる
        </button>
      </div>
    </div>
  );
}

export default GuestCTA;