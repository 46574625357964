import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { applyActionCode, checkActionCode, signOut } from 'firebase/auth';
import { updateUserProfile, checkFirestoreHealth } from '../services/firestore';

function EmailVerification() {
  const [verificationStatus, setVerificationStatus] = useState('verifying');
  const navigate = useNavigate();

  const forceLogoutAndRedirect = useCallback(async (message) => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
    navigate('/auth', { state: { message } });
  }, [navigate]);

  const handleEmailVerification = useCallback(async (actionCode) => {
    try {
      const isFirestoreHealthy = await checkFirestoreHealth();
      if (!isFirestoreHealthy) {
        throw new Error('Firestore is currently unavailable');
      }

      const checkResult = await checkActionCode(auth, actionCode);
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is currently signed in');
      }

      if (user.email !== checkResult.data.previousEmail) {
        throw new Error('User mismatch detected');
      }

      await applyActionCode(auth, actionCode);
      await updateUserProfile(user.uid, { email: checkResult.data.email });

      return { success: true };
    } catch (error) {
      console.error('Verification and change email error:', error);
      return { success: false, error };
    }
  }, []);

  useEffect(() => {
    const verifyAndChangeEmail = async () => {
      const params = new URLSearchParams(window.location.search);
      const mode = params.get('mode');
      const actionCode = params.get('oobCode');

      if (mode !== 'verifyAndChangeEmail' || !actionCode) {
        setVerificationStatus('error');
        return;
      }

      const result = await handleEmailVerification(actionCode);

      if (result.success) {
        setVerificationStatus('success');
        setTimeout(() => forceLogoutAndRedirect('セキュリティのため再度ログインしてください。'), 3000);
      } else {
        const error = result.error;
        if (error.message.includes('Firestore is currently unavailable')) {
          setVerificationStatus('firestore-error');
        } else if (error.code === 'auth/invalid-action-code') {
          setVerificationStatus('invalid-code');
        } else if (error.code === 'auth/user-disabled') {
          setVerificationStatus('user-disabled');
        } else if (error.code === 'auth/user-not-found') {
          setVerificationStatus('user-not-found');
        } else if (error.code === 'auth/expired-action-code') {
          setVerificationStatus('expired-code');
        } else if (error.message.includes('No user is currently signed in')) {
          await forceLogoutAndRedirect('セキュリティのため、ログインした状態で確認用リンクを開いてください。');
        } else if (error.message.includes('User mismatch detected')) {
          await forceLogoutAndRedirect('異なるユーザーでログインしているようです。設定するユーザーでログインした状態で確認用リンクを開いてください。');
        } else {
          setVerificationStatus('error');
        }
      }
    };

    verifyAndChangeEmail();
  }, [navigate, forceLogoutAndRedirect, handleEmailVerification]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      {verificationStatus === 'verifying' && <p>メールアドレスを確認中...</p>}
      {verificationStatus === 'success' && <p>メールアドレスが確認され、更新されました。<br/>セキュリティのため再度ログインしてください。</p>}
      {verificationStatus === 'invalid-code' && <p>確認コードが無効または期限切れです。新しい確認メールを使用してください。</p>}
      {verificationStatus === 'user-disabled' && <p>このアカウントは無効化されています。サポートにお問い合わせください。</p>}
      {verificationStatus === 'user-not-found' && <p>ユーザーが見つかりません。アカウントが削除された可能性があります。</p>}
      {verificationStatus === 'firestore-error' && <p>サーバーが一時的に利用できません。<br/>しばらくしてから再度お試しください。</p>}
      {verificationStatus === 'error' && <p>メールアドレスの確認と更新に失敗しました。もう一度お試しください。</p>}
    </div>
  );
}

export default EmailVerification;