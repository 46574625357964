import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { followUser, unfollowUser } from '../services/firestore';
import Modal from './Modal';

function FollowButton({ userId, userName, size = 'default', shape = 'rounded-xl', initialIsFollowing, onFollowChange }) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isFollowing, setIsFollowing] = useState(initialIsFollowing);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsFollowing(initialIsFollowing);
  }, [initialIsFollowing]);

  const handleToggleFollow = async () => {
    if (!currentUser) {
      navigate('/auth');
      return;
    }

    if (isFollowing) {
      setIsModalOpen(true);
    } else {
      await toggleFollow();
    }
  };

  const toggleFollow = async () => {
    setIsLoading(true);
    try {
      if (isFollowing) {
        await unfollowUser(currentUser.uid, userId);
      } else {
        await followUser(currentUser.uid, userId);
      }
      setIsFollowing(!isFollowing);
      if (onFollowChange) {
        onFollowChange(!isFollowing);
      }
    } catch (error) {
      console.error('Error toggling follow:', error);
      // エラーメッセージを表示（トースト通知など）
    } finally {
      setIsLoading(false);
    }
  };

  const getSizeClass = () => {
    switch (size) {
      case 'small': return 'py-1.5 w-20 text-sm';
      case 'large': return 'py-2 px-6 text-lg';
      default: return 'py-1.5 w-24';
    }
  };

  const buttonClass = `
    ${getSizeClass()}
    ${shape}
    ${isFollowing
      ? 'bg-gray-300 text-gray-700 hover:bg-gray-400'
      : 'bg-blue-500 text-white hover:bg-blue-600'
    }
    transition-colors duration-200
    ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}
  `;

  return (
    <>
      <button
        onClick={handleToggleFollow}
        className={buttonClass}
        disabled={isLoading}
      >
        <span className="block w-full text-center">
          {isFollowing ? 'フォロー中' : 'フォロー'}
        </span>
      </button>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => {
          setIsModalOpen(false);
          toggleFollow();
        }}
        title="フォロー解除"
        message={`${userName}のフォローを解除しますか？`}
        confirmText="フォロー解除"
        confirmColor="red"
      />
    </>
  );
}

export default FollowButton;