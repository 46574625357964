import React from 'react';
import { Link } from 'react-router-dom';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';

function AppBar() {
  return (
    <header className="bg-white dark:bg-gray-800 text-gray-800 dark:text-white p-3 flex justify-between items-center shadow-md">
      <h1 className="text-2xl font-bold">Zealync</h1>
      <Link to="/settings" className="text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-white">
        <Cog6ToothIcon className="h-6 w-6" />
      </Link>
    </header>
  );
}

export default AppBar;