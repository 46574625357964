import React, { useState, useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '../components/AppBar';
import BottomNav from '../components/BottomNav';
import { reauthenticateWithPassword, updateUserPassword } from '../services/auth';
import { validatePassword } from '../utils/passwordValidation';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

const PasswordInput = React.memo(({ id, value, onChange, showPassword, setShowPassword }) => {
  const inputRef = useRef(null);

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(prev => !prev);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [setShowPassword]);

  return (
    <div className="relative">
      <input
        ref={inputRef}
        type={showPassword ? "text" : "password"}
        id={id}
        value={value}
        onChange={onChange}
        required
        className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-gray-900 dark:text-white pr-10"
      />
      <button
        type="button"
        onClick={togglePasswordVisibility}
        className="absolute inset-y-0 right-0 pr-3 flex items-center"
      >
        {showPassword ? (
          <EyeSlashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        ) : (
          <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        )}
      </button>
    </div>
  );
});

function ChangePassword() {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setIsLoading(true);

    const passwordError = validatePassword(newPassword);
    if (passwordError) {
      setError(passwordError);
      setIsLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('新しいパスワードと確認用パスワードが一致しません。');
      setIsLoading(false);
      return;
    }

    try {
      await reauthenticateWithPassword(currentPassword);
      await updateUserPassword(newPassword);
      setSuccessMessage('パスワードが正常に更新されました。');
      setTimeout(() => navigate('/settings/account'), 3000);
    } catch (error) {
      console.error('Error updating password:', error);
      if (error.code === 'auth/wrong-password') {
        setError('現在のパスワードが正しくありません。');
      } else {
        setError('パスワードの更新に失敗しました。もう一度お試しください。');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCurrentPasswordChange = useCallback((e) => setCurrentPassword(e.target.value), []);
  const handleNewPasswordChange = useCallback((e) => setNewPassword(e.target.value), []);
  const handleConfirmPasswordChange = useCallback((e) => setConfirmPassword(e.target.value), []);

  const currentPasswordInput = useMemo(() => (
    <PasswordInput
      id="current-password"
      value={currentPassword}
      onChange={handleCurrentPasswordChange}
      showPassword={showCurrentPassword}
      setShowPassword={setShowCurrentPassword}
    />
  ), [currentPassword, showCurrentPassword, handleCurrentPasswordChange]);

  const newPasswordInput = useMemo(() => (
    <PasswordInput
      id="new-password"
      value={newPassword}
      onChange={handleNewPasswordChange}
      showPassword={showNewPassword}
      setShowPassword={setShowNewPassword}
    />
  ), [newPassword, showNewPassword, handleNewPasswordChange]);

  const confirmPasswordInput = useMemo(() => (
    <PasswordInput
      id="confirm-password"
      value={confirmPassword}
      onChange={handleConfirmPasswordChange}
      showPassword={showConfirmPassword}
      setShowPassword={setShowConfirmPassword}
    />
  ), [confirmPassword, showConfirmPassword, handleConfirmPasswordChange]);

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <AppBar />
      <main className="flex-grow overflow-y-auto p-4 pb-20">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">パスワード変更</h2>
        {error && (
          <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 dark:bg-red-900 dark:border-red-500 dark:text-red-300 rounded">
            {error}
          </div>
        )}
        {successMessage && (
          <div className="mb-4 p-4 bg-green-100 border border-green-400 text-green-700 dark:bg-green-900 dark:border-green-500 dark:text-green-300 rounded">
            {successMessage}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="current-password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              現在のパスワード
            </label>
            {currentPasswordInput}
          </div>
          <div>
            <label htmlFor="new-password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              新しいパスワード
            </label>
            {newPasswordInput}
          </div>
          <div>
            <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              新しいパスワード（確認）
            </label>
            {confirmPasswordInput}
          </div>
          <div className="flex justify-between">
            <button
              type="button"
              onClick={() => navigate('/settings/account')}
              className="bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-300 py-2 px-4 rounded hover:bg-gray-400 dark:hover:bg-gray-600 transition duration-300"
            >
              キャンセル
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300 disabled:opacity-50"
            >
              {isLoading ? '送信中...' : '送信'}
            </button>
          </div>
        </form>
      </main>
      <BottomNav />
    </div>
  );
}

export default ChangePassword;