import { db } from '../firebase';
import { collection, query, where, getDocs, orderBy, limit, startAfter } from 'firebase/firestore';

export const searchUsers = async (searchTerm, page = 1, limitCount = 10, signal) => {
  try {
    const usersRef = collection(db, 'users');
    const effectiveLimitCount = limitCount > 0 ? limitCount : 10;
    let q = query(
      usersRef,
      where('username', '>=', searchTerm.toLowerCase()),
      where('username', '<=', searchTerm.toLowerCase() + '\uf8ff'),
      orderBy('username'),
      limit(effectiveLimitCount)
    );

    if (page > 1) {
      const prevPageSnapshot = await getDocs(query(q, limit((page - 1) * effectiveLimitCount)));
      const lastDoc = prevPageSnapshot.docs[prevPageSnapshot.docs.length - 1];
      q = query(q, startAfter(lastDoc));
    }

    const querySnapshot = await getDocs(q);
    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({ id: doc.id, ...doc.data() });
    });

    return {
      results: users.map(user => ({
        id: user.id,
        username: user.username,
        displayName: user.displayName,
        bio: user.bio,
        avatar: user.avatar,
      })),
      totalPages: Math.ceil(querySnapshot.size / effectiveLimitCount),
      currentPage: page,
      hasMore: users.length === effectiveLimitCount
    };
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('User search aborted');
      throw error;
    }
    console.error('Error searching users:', error);
    throw new Error('ユーザーの検索中にエラーが発生しました。');
  }
};

export const searchContents = async (contentType, searchTerm, page = 1, limitCount = 10, signal) => {
  try {
    const contentsRef = collection(db, contentType);
    let q = query(
      contentsRef,
      where('title', '>=', searchTerm),
      where('title', '<=', searchTerm + '\uf8ff'),
      orderBy('title'),
      limit(limitCount)
    );

    if (page > 1) {
      const prevPageSnapshot = await getDocs(query(q, limit((page - 1) * limitCount)));
      const lastDoc = prevPageSnapshot.docs[prevPageSnapshot.docs.length - 1];
      q = query(q, startAfter(lastDoc));
    }

    const querySnapshot = await getDocs(q);
    const contents = [];
    querySnapshot.forEach((doc) => {
      contents.push({ id: doc.id, ...doc.data() });
    });

    return {
      results: contents.map(content => ({
        id: content.id,
        title: content.title,
        // contentTypeに応じて必要な情報を追加
      })),
      totalPages: Math.ceil(querySnapshot.size / limitCount),
      currentPage: page,
      hasMore: contents.length === limitCount
    };
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log(`${contentType} search aborted`);
      throw error;
    }
    console.error(`Error searching ${contentType}:`, error);
    throw new Error(`${contentType}の検索中にエラーが発生しました。`);
  }
};