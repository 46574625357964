import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HomeIcon, MagnifyingGlassIcon, UserGroupIcon, UserIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../contexts/AuthContext';

const NavItem = ({ to, label, icon: Icon, isActive, isProfile, avatar }) => (
  <Link to={to} className={`flex flex-col items-center ${isActive ? 'text-blue-600 dark:text-blue-400' : 'text-gray-500 dark:text-gray-400'}`}>
    {isProfile ? (
      avatar ? (
        <img src={avatar} alt="User Avatar" className="w-6 h-6 rounded-full object-cover" />
      ) : (
        <div className="w-6 h-6 rounded-full bg-gray-300 flex items-center justify-center">
          <UserIcon className="w-4 h-4 text-gray-500" />
        </div>
      )
    ) : (
      <Icon className={`w-6 h-6 ${isActive ? 'text-blue-600 dark:text-blue-400' : 'text-gray-500 dark:text-gray-400'}`} />
    )}
    <span className="text-xs mt-1">{label}</span>
  </Link>
);

function BottomNav() {
  const location = useLocation();
  const { currentUser } = useAuth();

  return (
    <nav className="fixed bottom-0 w-full bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
      <div className="flex justify-around items-center h-16">
        <NavItem
          to="/"
          label="ホーム"
          icon={HomeIcon}
          isActive={location.pathname === '/'}
        />
        <NavItem
          to="/search"
          label="検索"
          icon={MagnifyingGlassIcon}
          isActive={location.pathname === '/search'}
        />
        <NavItem
          to="/matching"
          label="マッチング"
          icon={UserGroupIcon}
          isActive={location.pathname === '/matching'}
        />
        <NavItem
          to={`/${currentUser?.username || '/'}`}
          label="プロフィール"
          icon={UserIcon}
          isActive={location.pathname === `/${currentUser?.username}`}
          isProfile={true}
          avatar={currentUser?.avatar}
        />
      </div>
    </nav>
  );
}

export default BottomNav;