export const validatePassword = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasNonalphas = /\W/.test(password);

  if (password.length < minLength) {
    return 'パスワードは最低8文字以上である必要があります。';
  }

  let conditionsMet = 0;
  if (hasUpperCase) conditionsMet++;
  if (hasLowerCase) conditionsMet++;
  if (hasNumbers) conditionsMet++;
  if (hasNonalphas) conditionsMet++;

  if (conditionsMet < 2) {
    return 'パスワードは大文字、小文字、数字、記号のうち2種類以上を組み合わせる必要があります。';
  }

  return null; // パスワードが要件を満たしている場合
};