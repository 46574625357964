import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { addFavorite, removeFavorite, checkIsFavorite } from '../services/firestore';
import { HeartIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid';
import Modal from './Modal';

function FavoriteButton({ contentId, contentType, contentName, imageSrc, initialIsFavorite }) {
  const { currentUser } = useAuth();
  const [isFavorite, setIsFavorite] = useState(initialIsFavorite);
  const [isLoading, setIsLoading] = useState(!initialIsFavorite);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const checkFavoriteStatus = useCallback(async () => {
    if (currentUser && !initialIsFavorite) {
      try {
        setIsLoading(true);
        const status = await checkIsFavorite(currentUser.uid, contentType, contentId);
        setIsFavorite(status);
      } catch (error) {
        console.error('Error checking favorite status:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [currentUser, contentId, contentType, initialIsFavorite]);

  useEffect(() => {
    checkFavoriteStatus();
  }, [checkFavoriteStatus]);

  const handleToggleFavorite = async () => {
    if (!currentUser) {
      alert('お気に入りに追加するにはログインしてください。');
      return;
    }

    if (isFavorite) {
      setIsModalOpen(true);
    } else {
      await toggleFavorite();
    }
  };

  const toggleFavorite = async () => {
    setIsLoading(true);
    const newFavoriteStatus = !isFavorite;
    setIsFavorite(newFavoriteStatus);

    try {
      if (newFavoriteStatus) {
        await addFavorite(currentUser.uid, contentType, contentId);
      } else {
        await removeFavorite(currentUser.uid, contentType, contentId);
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
      setIsFavorite(!newFavoriteStatus);
      // エラーメッセージを表示（トースト通知など）
    } finally {
      setIsLoading(false);
    }
  };

  const buttonClass = `
    w-8 h-8 rounded-full flex items-center justify-center
    transition-all duration-300 ease-in-out
    ${isFavorite
      ? (isHovered ? 'bg-red-600' : 'bg-red-500') + ' text-white'
      : (isHovered ? 'bg-gray-300' : 'bg-gray-200') + ' text-gray-600 dark:bg-gray-700 dark:text-gray-300'
    }
    ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}
  `;

  if (isLoading && !initialIsFavorite) {
    return <div className="w-8 h-8 bg-gray-200 dark:bg-gray-700 rounded-full animate-pulse"></div>;
  }

  return (
    <>
      <button
        onClick={handleToggleFavorite}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={buttonClass}
        disabled={isLoading}
        title={isFavorite ? 'お気に入りから削除' : 'お気に入りに追加'}
      >
        {isFavorite ? (
          <HeartIconSolid className="w-5 h-5" />
        ) : (
          <HeartIcon className="w-5 h-5" />
        )}
      </button>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => {
          setIsModalOpen(false);
          toggleFavorite();
        }}
        message={`${contentName}をお気に入りから削除しますか？`}
        confirmText="お気に入りから削除"
        imageSrc={imageSrc}
        modalWidth="narrow"
        messageAlign="center"
        confirmColor="red"
      />
    </>
  );
}

export default FavoriteButton;