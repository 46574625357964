const reservedUsernames = [
  // 一般的な管理用語
  'admin', 'administrator', 'root', 'system', 'moderator', 'mod', 'support',
  
  // 認証関連
  'login', 'logout', 'signin', 'signout', 'register', 'signup', 'password', 'reset',
  
  // ナビゲーション・ページ
  'home', 'search', 'profile', 'profiles', 'setting', 'settings', 'edit', 'delete',
  'user', 'users', 'about', 'contact', 'help', 'faq', 'terms', 'privacy', 'policy',
  
  // コンテンツ関連
  'movie', 'movies', 'book', 'books', 'music', 'song', 'songs', 'album', 'albums',
  'artist', 'artists', 'author', 'authors', 'director', 'directors', 'actor', 'actors',
  'game', 'games', 'anime', 'manga', 'comic', 'comics', 'tv', 'television', 'show', 'shows',
  'podcast', 'podcasts', 'playlist', 'playlists', 'drama', 'dramas', 'animation', 'animations',
  
  // ユーザーアクション
  'favorite', 'favorites', 'like', 'likes', 'follow', 'following', 'follower', 'followers',
  'friend', 'friends', 'block', 'blocked', 'mute', 'muted',
  
  // フィーチャー
  'recommendation', 'recommendations', 'suggest', 'suggestion', 'suggestions',
  'review', 'reviews', 'rating', 'ratings', 'comment', 'comments',
  'list', 'lists', 'category', 'categories', 'genre', 'genres',
  'trending', 'popular', 'new', 'latest', 'top', 'hot',
  
  // コミュニティ機能
  'message', 'messages', 'chat', 'notification', 'notifications',
  'event', 'events', 'group', 'groups', 'community', 'communities',
  
  // API・開発関連
  'api', 'oauth', 'token', 'app', 'application', 'dev', 'developer', 'test', 'testing',
  
  // その他
  'feed', 'activity', 'stream', 'discover', 'explore', 'tag', 'tags',
  'premium', 'pro', 'vip', 'official', 'verified', 'sponsored', 'ad', 'advertisement',
  'info', 'information', 'faq', 'help', 'support', 'contact',
  'temp', 'temporary', 'demo', 'example', 'sample',
  
  // Zealync特有の用語
  'zealync', 'zeal', 'sync', 'link', 'zealync_official'
];

const reservedUsernamesSet = new Set(reservedUsernames);

export const validateUsername = (username) => {
  // 小文字、数字、アンダースコアのみを許可し、3〜20文字の制限
  const regex = /^[a-z0-9_]{3,20}$/;
  
  if (!regex.test(username)) {
    return "ユーザーネームは3〜20文字の小文字英数字とアンダースコアのみ使用可能です。";
  }
  
  if (reservedUsernamesSet.has(username.toLowerCase())) {
    return "このユーザーネームは使用できません。";
  }
  
  return null; // エラーがない場合
};

export const isReservedUsername = (username) => {
  return reservedUsernamesSet.has(username.toLowerCase());
};