import React from 'react';
import AppBar from '../components/AppBar';
import BottomNav from '../components/BottomNav';
import Feed from '../components/Feed';

function Home() {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <AppBar />
      <main className="flex-grow overflow-y-auto pb-20">
        <div className="mt-4">
          <Feed />
        </div>
      </main>
      <BottomNav />
    </div>
  );
}

export default Home;