import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const Modal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmText,
  cancelText = 'キャンセル',
  isShowTitle = false,
  messageSize = 'medium',
  modalWidth = 'medium',
  imageSrc,
  confirmColor = 'blue',
  messageAlign = 'center'
}) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.addEventListener('keydown', handleEscKey);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscKey);
      document.body.style.overflow = '';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const getMessageSizeClass = () => {
    switch (messageSize) {
      case 'small': return 'text-sm';
      case 'large': return 'text-lg';
      default: return 'text-base';
    }
  };

  const getModalWidthClass = () => {
    switch (modalWidth) {
      case 'narrow': return 'max-w-xs';
      case 'wide': return 'max-w-2xl';
      default: return 'max-w-sm';
    }
  };

  const getConfirmColorClass = () => {
    switch (confirmColor) {
      case 'red': return 'bg-red-500 hover:bg-red-600';
      case 'green': return 'bg-green-500 hover:bg-green-600';
      default: return 'bg-blue-500 hover:bg-blue-600';
    }
  };

  const getMessageAlignClass = () => {
    switch (messageAlign) {
      case 'left': return 'text-left';
      case 'right': return 'text-right';
      default: return 'text-center';
    }
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div ref={modalRef} className={`bg-white dark:bg-gray-800 rounded-lg p-6 ${getModalWidthClass()} w-full mx-4`}>
        {isShowTitle && (
          <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">{title}</h2>
        )}
        {imageSrc && (
          <div className="flex justify-center mb-4">
            <img src={imageSrc} alt="Content" className="w-32 h-32 object-cover rounded" />
          </div>
        )}
        <p className={`${getMessageSizeClass()} ${getMessageAlignClass()} text-gray-800 dark:text-gray-200 mb-6`}>{message}</p>
        <div className="flex flex-col space-y-2">
          <button
            onClick={onConfirm}
            className={`w-full px-4 py-2 ${getConfirmColorClass()} text-white rounded transition duration-300`}
          >
            {confirmText}
          </button>
          <button
            onClick={onClose}
            className="w-full px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-300"
          >
            {cancelText}
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;