import { db, auth } from '../firebase';
import { doc, setDoc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';

export const createUserProfile = async (user, additionalData) => {
  if (!user) return;

  const userRef = doc(db, 'users', user.uid);
  const snapshot = await getDoc(userRef);

  if (!snapshot.exists()) {
    const { email } = user;
    const { username, displayName, avatar } = additionalData;
    const createdAt = new Date();

    try {
      await setDoc(userRef, {
        email,
        username,
        displayName,
        avatar,
        createdAt,
      });

      await updateProfile(user, { displayName: displayName });
    } catch (error) {
      console.error("Error creating user profile", error);
      throw error;
    }
  }

  return getUserProfile(user.uid);
};

export const updateUserProfile = async (userId, updateData) => {
  const userRef = doc(db, 'users', userId);

  try {
    await updateDoc(userRef, updateData);

    // displayNameが更新される場合、Firebase Authenticationも更新
    if (updateData.displayName) {
      const user = auth.currentUser;
      if (user) {
        await updateProfile(user, { displayName: updateData.displayName });
      }
    }
  } catch (error) {
    console.error("Error updating user profile", error);
    throw error;
  }
};

export const getUserProfile = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const snapshot = await getDoc(userRef);

  if (snapshot.exists()) {
    return snapshot.data();
  } else {
    throw new Error("User profile not found");
  }
};

export const checkUsernameAvailability = async (username) => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where("username", "==", username));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;
  } catch (error) {
    console.error("Error checking username availability", error);
    throw error;
  }
};

export const validateDisplayName = (displayName) => {
  if (displayName.length < 1 || displayName.length > 50) {
    return "ディスプレイネームは1〜50文字で入力してください。";
  }
  return null;
};