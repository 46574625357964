import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getUserByUsername, getFavorites, getBulkFavoriteStatus, checkIsFollowing } from '../services/firestore';
import { getMovieDetails, getBookDetails, getMusicDetails } from '../services/externalApiService';
import AppBar from '../components/AppBar';
import BottomNav from '../components/BottomNav';
import ContentList from '../components/ContentList';
import GuestCTA from '../components/GuestCTA';
import LoadingComponent from '../components/LoadingComponent';
import FollowButton from '../components/FollowButton';

function Profile() {
  const { username } = useParams();
  const { currentUser } = useAuth();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [favoriteDetails, setFavoriteDetails] = useState({ movies: [], books: [], music: [] });
  const [activeCategory, setActiveCategory] = useState('movies');
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);

  const updateFollowerCount = (isIncrement) => {
    setUser(prevUser => ({
      ...prevUser,
      followers: isIncrement
        ? [...(prevUser.followers || []), currentUser.uid]
        : (prevUser.followers || []).filter(id => id !== currentUser.uid)
    }));
    setIsFollowing(isIncrement);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setIsDataLoading(true);
      setError(null);
      try {
        let userData = await getUserByUsername(username);

        if (!userData) {
          throw new Error('ユーザーが見つかりません。');
        }

        setUser(userData);

        if (currentUser && currentUser.uid !== userData.id) {
          const followStatus = await checkIsFollowing(currentUser.uid, userData.id);
          setIsFollowing(followStatus);
        }

        const userFavorites = await getFavorites(userData.id);

        const fetchDetailsForCategory = async (ids, getDetailsFunction, category) => {
          if (ids.length === 0) return [];
          try {
            if (category === 'music') {
              return await getDetailsFunction(ids);
            } else {
              const details = await Promise.all(ids.map(id => getDetailsFunction(id)));
              return details;
            }
          } catch (error) {
            console.error(`${category}の詳細取得中にエラーが発生:`, error);
            return [];
          }
        };

        const [movieDetails, bookDetails, musicDetails] = await Promise.all([
          fetchDetailsForCategory(userFavorites.movies || [], getMovieDetails, 'movies'),
          fetchDetailsForCategory(userFavorites.books || [], getBookDetails, 'books'),
          fetchDetailsForCategory(userFavorites.music || [], getMusicDetails, 'music')
        ]);

        const allDetails = [...movieDetails, ...bookDetails, ...musicDetails];
        const favoriteStatus = await getBulkFavoriteStatus(
          userData.id,
          'all',
          allDetails.map(item => item.id)
        );

        setFavoriteDetails({
          movies: movieDetails.map(item => ({ ...item, isFavorite: favoriteStatus[item.id] || false })),
          books: bookDetails.map(item => ({ ...item, isFavorite: favoriteStatus[item.id] || false })),
          music: musicDetails.map(item => ({ ...item, isFavorite: favoriteStatus[item.id] || false })),
        });
      } catch (error) {
        console.error('ユーザーデータ取得中のエラー:', error);
        setError(error.message || 'ユーザーデータの取得中にエラーが発生しました。');
      } finally {
        setIsDataLoading(false);
      }
    };

    fetchUserData();
  }, [username, currentUser]);

  useEffect(() => {
    if (!isDataLoading) {
      setIsLoading(false);
    }
  }, [isDataLoading]);

  if (isLoading) return <LoadingComponent />;
  if (error) return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;
  if (!user) return <div className="flex justify-center items-center h-screen">ユーザーが見つかりません。</div>;

  const categoryLabels = {
    movies: '映画',
    books: '書籍',
    music: '音楽'
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <AppBar />
      <main className="flex-grow overflow-y-auto pb-20">
        {/* ユーザー情報 */}
        <div className="bg-white dark:bg-gray-800 shadow-md p-4">
          <div className="flex justify-between items-center mb-4">
            <img
              src={user.avatar}
              alt={user.displayName}
              className="w-20 h-20 rounded-full"
            />
            <div>
              {currentUser && currentUser.username === username ? (
                <Link
                  to="/settings/edit-profile"
                  state={{ from: location.pathname }}
                  className="bg-blue-500 text-white py-1.5 px-4 rounded-lg hover:bg-blue-600 text-center block"
                >
                  プロフィールを編集
                </Link>
              ) : (
                <FollowButton
                  userId={user.id}
                  userName={user.displayName}
                  shape="rounded-full"
                  initialIsFollowing={isFollowing}
                  onFollowChange={updateFollowerCount}
                />
              )}
            </div>
          </div>
          <div>
            <h2 className="text-2xl font-bold text-gray-800 dark:text-white">{user.displayName}</h2>
            <p className="text-sm text-gray-600 dark:text-gray-400">@{user.username}</p>
            <p className="text-gray-600 dark:text-gray-300 mt-2">{user.bio}</p>
            <div className="flex mt-2 space-x-4">
              <Link to={`/${username}/following`} className="text-sm text-gray-600 dark:text-gray-300">
                <span className="font-bold">{user.following?.length || 0}</span> フォロー中
              </Link>
              <Link to={`/${username}/followers`} className="text-sm text-gray-600 dark:text-gray-300">
                <span className="font-bold">{user.followers?.length || 0}</span> フォロワー
              </Link>
            </div>
          </div>
        </div>

        {/* お気に入りカテゴリータブ */}
        <div className="flex bg-white dark:bg-gray-800 shadow-md mt-4">
          {Object.entries(categoryLabels).map(([key, label]) => (
            <button
              key={key}
              className={`flex-1 py-2 px-4 text-center ${
                activeCategory === key
                  ? 'bg-blue-500 text-white'
                  : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
              onClick={() => setActiveCategory(key)}
            >
              {label}
            </button>
          ))}
        </div>

        {/* お気に入りリスト */}
        <div className="mt-4 bg-white dark:bg-gray-800 shadow-md">
          {favoriteDetails[activeCategory].length > 0 ? (
            <ContentList
              items={favoriteDetails[activeCategory]}
              category={activeCategory}
              isGuest={!currentUser}
            />
          ) : (
            <p className="p-4 text-center text-gray-600 dark:text-gray-400">
              このカテゴリーにはまだお気に入りがありません。
            </p>
          )}
        </div>
      </main>
      {currentUser ? <BottomNav /> : <GuestCTA />}
    </div>
  );
}

export default Profile;