import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import AppBar from '../components/AppBar';
import BottomNav from '../components/BottomNav';
import FollowButton from '../components/FollowButton';
import FavoriteButton from '../components/FavoriteButton';
import { searchUsers } from '../services/searchService';
import { searchMovies, searchBooks, searchMusic } from '../services/externalApiService';
import { checkIsFollowing, getBulkFavoriteStatus } from '../services/firestore';
import { useAuth } from '../contexts/AuthContext';

const categories = [
  { id: 'movies', label: '映画' },
  { id: 'music', label: '音楽' },
  { id: 'books', label: '書籍' },
  { id: 'users', label: 'ユーザー' }
];

const SEARCH_STATES = {
  INITIAL: 'initial',
  SEARCHING: 'searching',
  RESULTS: 'results',
  NO_RESULTS: 'no-results'
};

function Search() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const [activeCategory, setActiveCategory] = useState('movies');
  const [inputValue, setInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchState, setSearchState] = useState(SEARCH_STATES.INITIAL);
  const searchControllerRef = useRef(null);
  const searchResultsRef = useRef(new Set());
  const scrollRef = useRef(null);

  const performSearch = useCallback(async (query, category, pageNum, isLoadingMore = false) => {
    if (searchControllerRef.current) {
      searchControllerRef.current.abort();
    }
    const newController = new AbortController();
    searchControllerRef.current = newController;

    if (!query) {
      setSearchResults([]);
      setHasMore(false);
      setSearchState(SEARCH_STATES.INITIAL);
      return;
    }
    setError(null);
    if (!isLoadingMore) {
      setSearchState(SEARCH_STATES.SEARCHING);
      setSearchResults([]);
      searchResultsRef.current.clear();
    }
    try {
      let results;
      switch (category) {
        case 'users':
          results = await searchUsers(query, pageNum, 10, newController.signal);
          if (currentUser) {
            results.results = await Promise.all(results.results.map(async (user) => {
              if (user.id !== currentUser.uid) {
                const isFollowing = await checkIsFollowing(currentUser.uid, user.id);
                return { ...user, isFollowing };
              }
              return user;
            }));
          }
          break;
        case 'movies':
          results = await searchMovies(query, pageNum, newController.signal);
          break;
        case 'music':
          results = await searchMusic(query, (pageNum - 1) * 20, newController.signal);
          break;
        case 'books':
          results = await searchBooks(query, (pageNum - 1) * 10, newController.signal);
          break;
        default:
          throw new Error('Invalid category');
      }
      if (newController.signal.aborted) {
        return;
      }
      const newResults = results.results.filter(result => !searchResultsRef.current.has(result.id));
      newResults.forEach(result => searchResultsRef.current.add(result.id));

      if (currentUser && category !== 'users') {
        const favoriteStatus = await getBulkFavoriteStatus(
          currentUser.uid,
          category,
          newResults.map(result => result.id)
        );
        newResults.forEach(result => {
          result.isFavorite = favoriteStatus[result.id] || false;
        });
      }

      setSearchResults(prevResults => {
        const updatedResults = [...prevResults, ...newResults];
        return updatedResults;
      });
      setHasMore(results.hasMore && newResults.length > 0);
      setSearchState(newResults.length > 0 ? SEARCH_STATES.RESULTS : SEARCH_STATES.NO_RESULTS);
    } catch (error) {
      if (error.name === 'AbortError' || error.message === 'AbortError') {
        return;
      }
      setError("検索中にエラーが発生しました。もう一度お試しください。");
      setHasMore(false);
      setSearchState(SEARCH_STATES.NO_RESULTS);
    }
  }, [currentUser]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('q') || '';
    const category = params.get('category') || 'movies';

    setInputValue(query);
    setSearchQuery(query);
    setActiveCategory(category);
    setPage(1);

  }, [location.search]);

  useEffect(() => {
    if (searchQuery) {
      performSearch(searchQuery, activeCategory, 1);
    } else {
      setSearchResults([]);
      setHasMore(false);
      setSearchState(SEARCH_STATES.INITIAL);
    }

    return () => {
      if (searchControllerRef.current) {
        searchControllerRef.current.abort();
      }
    };
  }, [searchQuery, activeCategory, performSearch]);

  const handleSearch = (e) => {
    e.preventDefault();
    const params = new URLSearchParams();
    if (inputValue) params.append('q', inputValue);
    params.append('category', activeCategory);
    navigate(`/search?${params.toString()}`);
    setSearchQuery(inputValue);
  };

  const handleCategoryChange = (categoryId) => {
    if (categoryId !== activeCategory) {
      setSearchResults([]);
      setSearchState(SEARCH_STATES.SEARCHING);
      const params = new URLSearchParams(location.search);
      params.set('category', categoryId);
      navigate(`/search?${params.toString()}`);
      setActiveCategory(categoryId);
    }
  };

  const loadMore = () => {
    if (hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
      performSearch(searchQuery, activeCategory, nextPage, true);
    }
  };

  const handleFollowChange = useCallback((userId, isFollowing) => {
    setSearchResults(prevResults =>
      prevResults.map(user =>
        user.id === userId ? { ...user, isFollowing } : user
      )
    );
  }, []);

  const renderSearchResult = useCallback((result) => {
    switch (activeCategory) {
      case 'users':
        return (
          <li className="py-4">
            <div className="flex items-center space-x-4">
              <Link to={`/${result.username}`} className="flex-shrink-0">
                <img src={result.avatar || 'https://via.placeholder.com/40'} alt={result.displayName} className="w-12 h-12 rounded-full" />
              </Link>
              <div className="flex-grow min-w-0">
                <Link to={`/${result.username}`} className="block">
                  <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                    {result.displayName}
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                    @{result.username}
                  </p>
                </Link>
                {result.bio && (
                  <p className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2">
                    {result.bio}
                  </p>
                )}
              </div>
              {currentUser && currentUser.uid !== result.id && (
                <div className="flex-shrink-0">
                  <FollowButton
                    userId={result.id}
                    userName={result.username}
                    size="small"
                    shape="rounded-full"
                    initialIsFollowing={result.isFollowing}
                    onFollowChange={(isFollowing) => handleFollowChange(result.id, isFollowing)}
                  />
                </div>
              )}
            </div>
          </li>
        );
      case 'movies':
        return (
          <li className="py-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2 flex-1 mr-2">
                {result.posterPath && (
                  <img src={result.posterPath} alt={result.title} className="w-16 h-24 object-cover rounded" />
                )}
                <div>
                  <span className="font-bold text-gray-900 dark:text-white">{result.title}</span>
                  <span className="block text-sm text-gray-500 dark:text-gray-400">
                    {result.releaseDate?.split('-')[0]}
                  </span>
                </div>
              </div>
              <FavoriteButton
                contentId={result.id}
                contentType={activeCategory}
                contentName={result.title}
                imageSrc={result.posterPath}
                initialIsFavorite={result.isFavorite}
              />
            </div>
          </li>
        );
      case 'music':
        return (
          <li className="py-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2 flex-1 mr-2">
                {result.albumArt && (
                  <img src={result.albumArt} alt={result.title} className="w-16 h-16 object-cover rounded" />
                )}
                <div>
                  <span className="font-bold text-gray-900 dark:text-white">{result.title}</span>
                  <span className="block text-sm text-gray-500 dark:text-gray-400">
                    {result.artist} - {result.album}
                  </span>
                </div>
              </div>
              <FavoriteButton
                contentId={result.id}
                contentType={activeCategory}
                contentName={result.title}
                imageSrc={result.albumArt}
                initialIsFavorite={result.isFavorite}
              />
            </div>
          </li>
        );
      case 'books':
        return (
          <li className="py-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2 flex-1 mr-2">
                {result.thumbnail && (
                  <img src={result.thumbnail} alt={result.title} className="w-16 h-24 object-cover rounded" />
                )}
                <div>
                  <span className="font-bold text-gray-900 dark:text-white">{result.title}</span>
                  <span className="block text-sm text-gray-500 dark:text-gray-400">
                    {result.authors?.join(', ')}
                  </span>
                  <span className="block text-sm text-gray-500 dark:text-gray-400">
                    {result.publishedDate}
                  </span>
                </div>
              </div>
              <FavoriteButton
                contentId={result.id}
                contentType={activeCategory}
                contentName={result.title}
                imageSrc={result.thumbnail}
                initialIsFavorite={result.isFavorite}
              />
            </div>
          </li>
        );
      default:
        return null;
    }
  }, [activeCategory, currentUser, handleFollowChange]);

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <AppBar />
      <main className="flex-grow overflow-y-auto pb-20" ref={scrollRef}>
        <form onSubmit={handleSearch} className="p-4 bg-white dark:bg-gray-800 shadow-md">
          <div className="flex">
            <input
              type="text"
              placeholder="検索..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="flex-grow p-2 border border-gray-300 dark:border-gray-700 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
              aria-label="検索キーワード"
            />
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="検索実行"
            >
              検索
            </button>
          </div>
        </form>

        <div className="flex overflow-x-auto bg-white dark:bg-gray-800 shadow-md" role="tablist">
        {categories.map(category => (
          <button
            key={category.id}
            className={`flex-shrink-0 py-2 px-4 text-center ${
              activeCategory === category.id
                ? 'bg-blue-500 text-white'
                : 'text-gray-700 dark:text-gray-300'
            }`}
            onClick={() => handleCategoryChange(category.id)}
            role="tab"
            aria-selected={activeCategory === category.id}
            aria-controls={`${category.id}-results`}
          >
            {category.label}
          </button>
        ))}
        </div>

        <div className="p-4">
          {error && <p className="text-center text-red-500" role="alert">{error}</p>}
          <InfiniteScroll
            dataLength={searchResults.length}
            next={loadMore}
            hasMore={hasMore}
            loader={<p className="text-center text-gray-700 dark:text-gray-300">読み込み中...</p>}
            scrollThreshold="200px"
            scrollableTarget="scrollRef"
          >
            {searchState === SEARCH_STATES.SEARCHING && <p className="text-center text-gray-700 dark:text-gray-300">検索中...</p>}
            {searchState === SEARCH_STATES.NO_RESULTS && <p className="text-center text-gray-700 dark:text-gray-300">検索結果がありません。</p>}
            {searchState === SEARCH_STATES.RESULTS && (
              <ul className="divide-y divide-gray-200 dark:divide-gray-700" aria-label={`${activeCategory}の検索結果`}>
                {searchResults.map(result => (
                  <React.Fragment key={`${activeCategory}-${result.id}`}>
                    {renderSearchResult(result)}
                  </React.Fragment>
                ))}
              </ul>
            )}
            {searchState === SEARCH_STATES.INITIAL && <p className="text-center text-gray-700 dark:text-gray-300">検索してください。</p>}
          </InfiniteScroll>
        </div>
      </main>
      <BottomNav />
    </div>
  );
}

export default Search;