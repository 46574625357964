import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import AppBar from '../components/AppBar';
import BottomNav from '../components/BottomNav';
import { reauthenticateWithPassword, updateUserProfile } from '../services/auth';
import { checkUsernameAvailability } from '../services/userProfile';
import { validateUsername } from '../utils/usernameValidation';

function ChangeUsername() {
  const { currentUser, updateCurrentUserProfile } = useAuth();
  const navigate = useNavigate();
  const [newUsername, setNewUsername] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    const usernameError = validateUsername(newUsername);
    if (usernameError) {
      setError(usernameError);
      setIsLoading(false);
      return;
    }

    try {
      if (newUsername !== currentUser.username) {
        const isAvailable = await checkUsernameAvailability(newUsername);
        if (!isAvailable) {
          setError('このユーザー名は既に使用されています。');
          setIsLoading(false);
          return;
        }
      }

      await reauthenticateWithPassword(currentPassword);
      await updateUserProfile(currentUser.uid, { username: newUsername });
      await updateCurrentUserProfile();
      navigate('/settings/account');
    } catch (error) {
      if (error.code === 'auth/invalid-credential') {
        setError('パスワードが正しくありません。');
      } else {
        setError('認証に失敗しました。もう一度お試しください。');
      }
      console.error('Error during re-authentication or update:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <AppBar />
      <main className="flex-grow overflow-y-auto p-4 pb-20">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">ユーザー名変更</h2>
        {error && (
          <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="current-username" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              現在のユーザー名
            </label>
            <input
              type="text"
              id="current-username"
              value={currentUser.username}
              disabled
              className="mt-1 block w-full px-3 py-2 bg-gray-100 dark:bg-gray-900 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-gray-700 dark:text-gray-300"
            />
          </div>
          <div>
            <label htmlFor="new-username" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              新しいユーザー名
            </label>
            <input
              type="text"
              id="new-username"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-gray-700 dark:text-gray-300"
            />
          </div>
          <div>
            <label htmlFor="current-password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              パスワード
            </label>
            <input
              type="password"
              id="current-password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-gray-700 dark:text-gray-300"
            />
          </div>
          <div className="flex justify-between">
            <button
              type="button"
              onClick={() => navigate('/settings/account')}
              className="bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-300 py-2 px-4 rounded hover:bg-gray-400 dark:hover:bg-gray-600 transition duration-300"
            >
              キャンセル
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300 disabled:opacity-50"
            >
              {isLoading ? '送信中...' : '送信'}
            </button>
          </div>
        </form>
      </main>
      <BottomNav />
    </div>
  );
}

export default ChangeUsername;