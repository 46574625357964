import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import AppBar from '../components/AppBar';
import BottomNav from '../components/BottomNav';

// ダミーデータ（後でAPIやFirebaseから取得するデータに置き換えます）
const dummyContent = {
  movie: {
    title: '君の名は。',
    director: '新海誠',
    releaseYear: 2016,
    description: '田舎に住む女子高校生と東京に住む男子高校生が入れ替わる現象を軸に展開するアニメーション映画。',
    genre: ['アニメ', 'ロマンス', 'ファンタジー'],
  },
  book: {
    title: '1Q84',
    author: '村上春樹',
    publishYear: 2009,
    description: '1984年の東京を舞台に、平行世界を行き来する二人の主人公の物語。',
    genre: ['小説', 'ファンタジー', 'ミステリー'],
  },
  music: {
    title: 'Shape of You',
    artist: 'Ed Sheeran',
    releaseYear: 2017,
    description: 'Ed Sheeranの3枚目のスタジオアルバム「÷」からのリードシングル。',
    genre: ['ポップ', 'トロピカルハウス'],
  },
};

function ContentDetail() {
  // eslint-disable-next-line no-unused-vars
  const { contentType, contentId } = useParams();
  const [isFavorite, setIsFavorite] = useState(false);

  // 実際のアプリケーションでは、contentIdを使用してデータを取得します
  const content = dummyContent[contentType];

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
    // ここでFirebaseにお気に入りの状態を保存する処理を追加します
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <AppBar />
      <main className="flex-grow overflow-y-auto p-4 pb-20">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">{content.title}</h2>
          <div className="mb-4">
            <p className="text-gray-600 dark:text-gray-300">
              {contentType === 'movie' && `監督: ${content.director}`}
              {contentType === 'book' && `著者: ${content.author}`}
              {contentType === 'music' && `アーティスト: ${content.artist}`}
            </p>
            <p className="text-gray-600 dark:text-gray-300">公開年: {content.releaseYear || content.publishYear}</p>
          </div>
          <p className="text-gray-700 dark:text-gray-300 mb-4">{content.description}</p>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-white">ジャンル</h3>
            <div className="flex flex-wrap gap-2">
              {content.genre.map((genre, index) => (
                <span key={index} className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                  {genre}
                </span>
              ))}
            </div>
          </div>
          <button
            onClick={toggleFavorite}
            className={`w-full py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white ${
              isFavorite ? 'bg-red-600 hover:bg-red-700' : 'bg-blue-600 hover:bg-blue-700'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
          >
            {isFavorite ? 'お気に入りから削除' : 'お気に入りに追加'}
          </button>
        </div>
        <div className="mt-4">
          <Link
            to="/search"
            className="block w-full text-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:bg-gray-700"
          >
            検索に戻る
          </Link>
        </div>
      </main>
      <BottomNav />
    </div>
  );
}

export default ContentDetail;