import React from 'react';
import FeedItem from './FeedItem';

const dummyFeed = [
  {
    id: 1,
    user: {
      name: "山田太郎",
      avatar: "https://i.pravatar.cc/150?img=1"
    },
    content: {
      type: "movie",
      title: "君の名は。",
      comment: "この映画の美しい風景と感動的なストーリーに魅了されました。新海誠監督の作品の中で一番好きかも！"
    },
    timestamp: "2分前"
  },
  {
    id: 2,
    user: {
      name: "佐藤花子",
      avatar: "https://i.pravatar.cc/150?img=2"
    },
    content: {
      type: "music",
      title: "RADWIMPS - 前前前世",
      comment: "「君の名は。」のサウンドトラック最高！この曲を聴くと映画のシーンが蘇ってきます。"
    },
    timestamp: "15分前"
  },
  {
    id: 3,
    user: {
      name: "鈴木一郎",
      avatar: "https://i.pravatar.cc/150?img=3"
    },
    content: {
      type: "book",
      title: "1Q84",
      comment: null  // コメントなし
    },
    timestamp: "1時間前"
  },
  {
    id: 4,
    user: {
      name: "高橋美咲",
      avatar: "https://i.pravatar.cc/150?img=4"
    },
    content: {
      type: "movie",
      title: "天気の子",
      comment: "新海誠監督の最新作、やっぱり素晴らしかった！音楽と映像の調和が完璧です。"
    },
    timestamp: "2時間前"
  },
  {
    id: 5,
    user: {
      name: "伊藤健太",
      avatar: "https://i.pravatar.cc/150?img=5"
    },
    content: {
      type: "music",
      title: "Official髭男dism - Pretender",
      comment: null  // コメントなし
    },
    timestamp: "3時間前"
  },
  {
    id: 6,
    user: {
      name: "渡辺さくら",
      avatar: "https://i.pravatar.cc/150?img=6"
    },
    content: {
      type: "book",
      title: "コンビニ人間",
      comment: "現代社会の縮図を描いた傑作。主人公の生き方に共感と違和感を同時に覚えました。"
    },
    timestamp: "4時間前"
  },
  {
    id: 7,
    user: {
      name: "中村翔太",
      avatar: "https://i.pravatar.cc/150?img=7"
    },
    content: {
      type: "movie",
      title: "シン・ゴジラ",
      comment: "特撮とCGの融合が見事。日本の社会システムへの皮肉も効いています。"
    },
    timestamp: "5時間前"
  },
  {
    id: 8,
    user: {
      name: "小林愛",
      avatar: "https://i.pravatar.cc/150?img=8"
    },
    content: {
      type: "music",
      title: "米津玄師 - Lemon",
      comment: "心に染みる歌詞と旋律。何度聴いても飽きません。"
    },
    timestamp: "6時間前"
  },
  {
    id: 9,
    user: {
      name: "加藤優",
      avatar: "https://i.pravatar.cc/150?img=9"
    },
    content: {
      type: "book",
      title: "かがみの孤城",
      comment: null  // コメントなし
    },
    timestamp: "7時間前"
  },
  {
    id: 10,
    user: {
      name: "松本龍",
      avatar: "https://i.pravatar.cc/150?img=10"
    },
    content: {
      type: "movie",
      title: "鬼滅の刃 無限列車編",
      comment: "アニメ映画の新たな歴史を作った作品。迫力のアクションシーンに圧倒されました。"
    },
    timestamp: "8時間前"
  }
];

function Feed() {
  return (
    <div className="space-y-4">
      {dummyFeed.map(item => (
        <FeedItem
          key={item.id}
          user={item.user}
          content={item.content}
          timestamp={item.timestamp}
        />
      ))}
    </div>
  );
}

export default Feed;