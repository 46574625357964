import React, { useState } from 'react';
import AppBar from '../components/AppBar';
import BottomNav from '../components/BottomNav';

// ダミーデータ（後でFirebaseから取得するデータに置き換えます）
const dummyWorks = {
  movies: [
    { id: 1, title: '君の名は。' },
    { id: 2, title: '天気の子' },
    { id: 3, title: 'シン・エヴァンゲリオン劇場版' }
  ],
  books: [
    { id: 4, title: '1Q84' },
    { id: 5, title: 'ノルウェイの森' }
  ],
  music: [
    { id: 6, title: 'Shape of You' },
    { id: 7, title: 'Lemon' }
  ]
};

const dummyUsers = [
  { id: 2, name: '佐藤花子', avatar: 'https://i.pravatar.cc/150?img=2', matchCount: 4 },
  { id: 3, name: '鈴木一郎', avatar: 'https://i.pravatar.cc/150?img=3', matchCount: 3 },
  { id: 4, name: '田中明', avatar: 'https://i.pravatar.cc/150?img=4', matchCount: 2 },
  { id: 5, name: '高橋裕子', avatar: 'https://i.pravatar.cc/150?img=5', matchCount: 2 },
  { id: 6, name: '伊藤健太', avatar: 'https://i.pravatar.cc/150?img=6', matchCount: 1 },
  { id: 7, name: '渡辺美咲', avatar: 'https://i.pravatar.cc/150?img=7', matchCount: 1 }
];

function Matching() {
  const [selectedWorks, setSelectedWorks] = useState([]);
  const [minMatchCount, setMinMatchCount] = useState(1);
  const [showWorkSelector, setShowWorkSelector] = useState(false);

  const handleWorkSelect = (work) => {
    if (selectedWorks.find(w => w.id === work.id)) {
      setSelectedWorks(selectedWorks.filter(w => w.id !== work.id));
    } else {
      setSelectedWorks([...selectedWorks, work]);
    }
  };

  const handleMatch = () => {
    // ここでマッチング処理を行います（後でFirebaseと連携）
    console.log('Matching with:', selectedWorks);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <AppBar />
      <main className="flex-grow overflow-y-auto p-4 pb-20">
        {/* 選択した作品の表示エリア */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">選択した作品：</h3>
          <div className="flex flex-wrap gap-2">
            {selectedWorks.map(work => (
              <span key={work.id} className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                {work.title}
              </span>
            ))}
          </div>
          <button
            onClick={() => setShowWorkSelector(true)}
            className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            作品を選択
          </button>
        </div>

        {/* 作品選択モーダル */}
        {showWorkSelector && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
              <div className="mt-3 text-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">作品を選択</h3>
                <div className="mt-2 px-7 py-3">
                  {Object.entries(dummyWorks).map(([category, works]) => (
                    <div key={category} className="mb-4">
                      <h4 className="text-md font-semibold mb-2 text-gray-700 dark:text-gray-300">{category === 'movies' ? '映画' : category === 'books' ? '書籍' : '音楽'}</h4>
                      {works.map(work => (
                        <div key={work.id} className="flex items-center mb-2">
                          <input
                            type="checkbox"
                            id={`work-${work.id}`}
                            checked={selectedWorks.some(w => w.id === work.id)}
                            onChange={() => handleWorkSelect(work)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label htmlFor={`work-${work.id}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            {work.title}
                          </label>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    id="ok-btn"
                    onClick={() => setShowWorkSelector(false)}
                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* フィルター設定 */}
        <div className="mb-4">
          <label htmlFor="minMatchCount" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
            最小一致数：
          </label>
          <input
            type="range"
            id="minMatchCount"
            min="1"
            max="4"
            value={minMatchCount}
            onChange={(e) => setMinMatchCount(parseInt(e.target.value))}
            className="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer dark:bg-blue-700"
          />
          <span className="text-sm text-gray-600 dark:text-gray-400">{minMatchCount}</span>
        </div>

        {/* マッチングボタン */}
        <button
          onClick={handleMatch}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mb-4"
        >
          マッチング開始
        </button>

        {/* マッチングしたユーザーのリスト */}
        <div>
          <h3 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">マッチしたユーザー：</h3>
          <div className="space-y-4">
            {dummyUsers.filter(user => user.matchCount >= minMatchCount).map(user => (
              <div key={user.id} className="flex items-center space-x-4 bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
                <img src={user.avatar} alt={user.name} className="w-12 h-12 rounded-full" />
                <div>
                  <p className="text-gray-800 dark:text-white font-semibold">{user.name}</p>
                  <p className="text-sm text-gray-600 dark:text-gray-400">一致数: {user.matchCount}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
      <BottomNav />
    </div>
  );
}

export default Matching;