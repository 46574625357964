import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getUserProfile, updateUserProfile } from '../services/userProfile';
import AppBar from '../components/AppBar';
import BottomNav from '../components/BottomNav';
import LoadingComponent from '../components/LoadingComponent';

function EditProfile() {
  const { currentUser, updateCurrentUserProfile } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [displayName, setDisplayName] = useState('');
  const [bio, setBio] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [previousPath, setPreviousPath] = useState('/settings');

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userData = await getUserProfile(currentUser.uid);
          setDisplayName(userData.displayName || '');
          setBio(userData.bio || '');
          setImage(userData.avatar || null);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError('ユーザーデータの取得に失敗しました。');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserData();

    if (location.state && location.state.from) {
      setPreviousPath(location.state.from);
    }
  }, [currentUser, location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateForm()) return;

    setIsSaving(true);

    try {
      await updateUserProfile(currentUser.uid, {
        displayName,
        bio
      });

      await updateCurrentUserProfile();

      navigate(previousPath, { replace: true });
    } catch (error) {
      setError('プロフィールの更新中にエラーが発生しました。');
      console.error('Error updating profile:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const validateForm = () => {
    if (displayName.trim().length === 0) {
      setError('表示名は必須です。');
      return false;
    }
    if (bio.length > 100) {
      setError('自己紹介は100文字以内で入力してください。');
      return false;
    }
    return true;
  };

  const handleCancel = () => {
    navigate(previousPath);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <AppBar />
      <main className="flex-grow overflow-y-auto pb-20 px-8 sm:px-12 md:px-16 lg:px-20">
        <h1 className="text-2xl font-bold my-6 text-gray-800 dark:text-white">プロフィール編集</h1>
        {error && (
          <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="max-w-2xl mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg border border-gray-300 dark:border-gray-700">
          <div className="flex items-center mb-8">
            <div className="w-24 h-24 bg-gray-300 dark:bg-gray-600 rounded-full mr-6 overflow-hidden">
              {image ? (
                <img src={image} alt="プロフィール画像" className="w-full h-full object-cover" />
              ) : (
                <div className="w-full h-full flex items-center justify-center text-gray-500 dark:text-gray-400">
                  No Image
                </div>
              )}
            </div>
            <button type="button" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
              画像を変更
            </button>
          </div>

          <div className="mb-6">
            <label htmlFor="displayName" className="block text-base font-medium text-gray-700 dark:text-gray-300 mb-2">
              表示名
            </label>
            <input
              type="text"
              id="displayName"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              maxLength={20}
              className="block w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 dark:text-white"
            />
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">{displayName.length}/20文字</p>
          </div>

          <div className="mb-8">
            <label htmlFor="bio" className="block text-base font-medium text-gray-700 dark:text-gray-300 mb-2">
              自己紹介
            </label>
            <textarea
              id="bio"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              maxLength={100}
              rows={4}
              className="block w-full p-2 rounded-md border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white dark:bg-gray-700 dark:text-white resize-none"
            ></textarea>
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">{bio.length}/100文字</p>
          </div>

          <div className="flex justify-between">
            <button
              type="button"
              onClick={handleCancel}
              className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 py-2 px-6 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition duration-300"
            >
              キャンセル
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-6 rounded hover:bg-blue-600 transition duration-300 disabled:opacity-50"
              disabled={isSaving}
            >
              {isSaving ? '保存中...' : '保存'}
            </button>
          </div>
        </form>
      </main>
      <BottomNav />
    </div>
  );
}

export default EditProfile;