import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import AppBar from '../components/AppBar';
import BottomNav from '../components/BottomNav';
import { reauthenticateWithPassword } from '../services/auth';
import { validateEmail } from '../utils/emailValidation';
import { auth } from '../firebase';
import { verifyBeforeUpdateEmail } from 'firebase/auth';

function ChangeEmail() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [newEmail, setNewEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setIsLoading(true);

    const emailError = validateEmail(newEmail);
    if (emailError) {
      setError(emailError);
      setIsLoading(false);
      return;
    }

    const user = auth.currentUser;
    try {
      await reauthenticateWithPassword(currentPassword);
      await verifyBeforeUpdateEmail(user, newEmail);
      setSuccessMessage('確認メールを送信しました。メールを確認して、リンクをクリックしてください。');
      setTimeout(() => navigate('/settings/account'), 5000);
    } catch (error) {
      console.error('Error updating email:', error);
      if (error.code === 'auth/requires-recent-login') {
        throw new Error('セキュリティのため、再度ログインが必要です。ログアウトして再度ログインしてください。');
      } else if (error.code === 'auth/invalid-email') {
        throw new Error('無効なメールアドレスです。');
      } else if (error.code === 'auth/email-already-in-use') {
        throw new Error('このメールアドレスは既に使用されています。');
      } else {
        throw new Error('メールアドレスの更新に失敗しました。もう一度お試しください。');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <AppBar />
      <main className="flex-grow overflow-y-auto p-4 pb-20">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">メールアドレス変更</h2>
        {error && (
          <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
          </div>
        )}
        {successMessage ? (
          <div className="mb-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded">
            {successMessage}
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="current-email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                現在のメールアドレス
              </label>
              <input
                type="email"
                id="current-email"
                value={currentUser.email}
                disabled
                className="mt-1 block w-full px-3 py-2 bg-gray-100 dark:bg-gray-900 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-gray-700 dark:text-gray-300"
              />
            </div>
            <div>
              <label htmlFor="new-email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                新しいメールアドレス
              </label>
              <input
                type="email"
                id="new-email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:text-sm text-gray-700 dark:text-gray-300"
              />
            </div>
            <div>
              <label htmlFor="current-password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                パスワード
              </label>
              <input
                type="password"
                id="current-password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:text-sm text-gray-700 dark:text-gray-300"
              />
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                onClick={() => navigate('/settings/account')}
                className="bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-300 py-2 px-4 rounded hover:bg-gray-400 dark:hover:bg-gray-600 transition duration-300"
              >
                キャンセル
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300 disabled:opacity-50"
              >
                {isLoading ? '送信中...' : '送信'}
              </button>
            </div>
          </form>
        )}
      </main>
      <BottomNav />
    </div>
  );
}

export default ChangeEmail;