import React from 'react';

function FeedItem({ user, content, timestamp }) {
  return (
    <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4 mb-4">
      <div className="flex items-center mb-2">
        <img 
          src={user.avatar} 
          alt={user.name} 
          className="w-10 h-10 rounded-full mr-3"
        />
        <div>
          <h3 className="font-bold text-gray-800 dark:text-white">{user.name}</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">{timestamp}</p>
        </div>
      </div>
      <div className="mb-2">
        <span className="inline-block bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 text-xs px-2 rounded-full uppercase font-semibold tracking-wide">
          {content.type}
        </span>
      </div>
      <h4 className="font-semibold text-lg mb-2 text-gray-800 dark:text-white">{content.title}</h4>
      {content.comment && <p className="text-gray-700 dark:text-gray-300">{content.comment}</p>}
    </div>
  );
}

export default FeedItem;