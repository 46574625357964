import React from 'react';
import FavoriteButton from './FavoriteButton';

const ContentList = ({ items, category, isGuest }) => {
  const renderContent = (item) => {
    switch (category) {
      case 'movies':
        return (
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2 flex-1 mr-2">
              {item.posterPath && (
                <img src={item.posterPath} alt={item.title} className="w-16 h-24 object-cover rounded" />
              )}
              <div>
                <span className="font-bold">{item.title}</span>
                <span className="block text-sm text-gray-500">
                  {item.releaseDate?.split('-')[0]}
                </span>
              </div>
            </div>
            {!isGuest && (
              <FavoriteButton
                contentId={item.id}
                contentType={category}
                contentName={item.title}
                imageSrc={item.posterPath}
              />
            )}
          </div>
        );
      case 'music':
        return (
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2 flex-1 mr-2">
              {item.albumArt && (
                <img src={item.albumArt} alt={item.title} className="w-16 h-16 object-cover rounded" />
              )}
              <div>
                <span className="font-bold">{item.title}</span>
                <span className="block text-sm text-gray-500">
                  {item.artist} - {item.album}
                </span>
              </div>
            </div>
            {!isGuest && (
              <FavoriteButton
                contentId={item.id}
                contentType={category}
                contentName={item.title}
                imageSrc={item.albumArt}
              />
            )}
          </div>
        );
      case 'books':
        return (
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2 flex-1 mr-2">
              {item.thumbnail && (
                <img src={item.thumbnail} alt={item.title} className="w-16 h-24 object-cover rounded" />
              )}
              <div>
                <span className="font-bold">{item.title}</span>
                <span className="block text-sm text-gray-500">
                  {item.authors?.join(', ')}
                </span>
                <span className="block text-sm text-gray-500">
                  {item.publishedDate}
                </span>
              </div>
            </div>
            {!isGuest && (
              <FavoriteButton
                contentId={item.id}
                contentType={category}
                contentName={item.title}
                imageSrc={item.thumbnail}
              />
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <ul className="space-y-2">
      {items.map((item) => (
        <li key={`${category}-${item.id}`} className="bg-white dark:bg-gray-800 p-3 rounded shadow text-gray-800 dark:text-gray-200">
          {renderContent(item)}
        </li>
      ))}
    </ul>
  );
};

export default ContentList;