import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoadingComponent from './components/LoadingComponent';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Auth from './pages/Auth';
import Home from './pages/Home';
import Search from './pages/Search';
import Matching from './pages/Matching';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import EditProfile from './pages/EditProfile';
import AccountInfo from './pages/AccountInfo';
import ChangeUsername from './pages/ChangeUsername';
import ChangeEmail from './pages/ChangeEmail';
import ChangePassword from './pages/ChangePassword';
import EmailVerification from './pages/EmailVerification';
import ContentDetail from './pages/ContentDetail';
import FollowPage from './pages/FollowPage';

const PrivateRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return <LoadingComponent />;
  }

  if (!currentUser) {
    return <Navigate to="/auth" replace />;
  }

  return children;
};

const PublicRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return <LoadingComponent />;
  }

  if (currentUser) {
    return <Navigate to="/" replace />;
  }

  return children;
};

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route path="/auth" element={<PublicRoute><Auth /></PublicRoute>} />
              <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
              <Route path="/search" element={<PrivateRoute><Search /></PrivateRoute>} />
              <Route path="/matching" element={<PrivateRoute><Matching /></PrivateRoute>} />
              <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
              <Route path="/content/:contentType/:contentId" element={<PrivateRoute><ContentDetail /></PrivateRoute>} />
              <Route path="/settings/edit-profile" element={<PrivateRoute><EditProfile /></PrivateRoute>} />
              <Route path="/settings/account" element={<PrivateRoute><AccountInfo /></PrivateRoute>} />
              <Route path="/settings/account/username" element={<PrivateRoute><ChangeUsername /></PrivateRoute>} />
              <Route path="/settings/account/email" element={<PrivateRoute><ChangeEmail /></PrivateRoute>} />
              <Route path="/settings/account/password" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
              <Route path="/email-verification" element={<EmailVerification />} />
              <Route path="/:username" element={<Profile />} />
              <Route path="/:username/:type" element={<PrivateRoute><FollowPage /></PrivateRoute>} />
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;