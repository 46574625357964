import { db } from '../firebase';
import { collection, doc, setDoc, getDoc, updateDoc, arrayUnion, arrayRemove, query, where, getDocs, limit } from 'firebase/firestore';

export const createUserProfile = async (userId, data) => {
  try {
    await setDoc(doc(db, 'users', userId), data);
  } catch (error) {
    throw error;
  }
};

export const getUserProfile = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      return userSnap.data();
    } else {
      console.warn('User profile not found in Firestore');
      return null;
    }
  } catch (error) {
    console.error('Error getting user profile:', error);
    throw error;
  }
};

export const getUserByUsername = async (username) => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('username', '==', username), limit(1));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null;
    }

    const userDoc = querySnapshot.docs[0];
    return { id: userDoc.id, ...userDoc.data() };
  } catch (error) {
    console.error('Error getting user by username:', error);
    throw error;
  }
};

export async function checkFirestoreHealth() {
  try {
    const testDocRef = doc(db, '_health_check', 'test');
    await setDoc(testDocRef, { timestamp: Date.now() });
    await getDoc(testDocRef);
    return true;
  } catch (error) {
    console.error('Firestore health check failed:', error);
    return false;
  }
}

export const updateUserProfile = async (userId, data) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, data);
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

export const addFavorite = async (userId, category, item) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    [`favorites.${category}`]: arrayUnion(item)
  });
};

export const removeFavorite = async (userId, category, item) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    [`favorites.${category}`]: arrayRemove(item)
  });
};

export const getFavorites = async (userId) => {
  if (!userId) {
    console.error('getFavorites called with undefined userId');
    return { movies: [], books: [], music: [] };
  }

  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.favorites || { movies: [], books: [], music: [] };
    } else {
      console.log('User document not found, returning empty favorites');
      return { movies: [], books: [], music: [] };
    }
  } catch (error) {
    console.error('Error getting favorites:', error);
    throw error;
  }
};

export const getBulkFavoriteStatus = async (userId, contentType, contentIds) => {
  if (!userId || !contentType) {
    console.warn('Invalid parameters for getBulkFavoriteStatus: userId or contentType is missing');
    return {};
  }
  if (!contentIds || contentIds.length === 0) {
    return {};
  }

  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    const favorites = userDoc.data()?.favorites?.[contentType] || [];
    return contentIds.reduce((acc, id) => {
      acc[id] = favorites.includes(id);
      return acc;
    }, {});
  } catch (error) {
    console.error('Error getting bulk favorite status:', error);
    return {};
  }
};

export const searchUsers = async (searchTerm) => {
  const q = query(collection(db, 'users'), where('displayName', '>=', searchTerm), where('displayName', '<=', searchTerm + '\uf8ff'));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const searchContent = async (category, searchTerm) => {
  const q = query(collection(db, category), where('title', '>=', searchTerm), where('title', '<=', searchTerm + '\uf8ff'));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const checkIsFavorite = async (userId, category, itemId) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  const userData = userDoc.data();
  const favorites = userData?.favorites?.[category] || [];
  return favorites.includes(itemId);
};

export const checkIsFollowing = async (userId, targetUserId) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  const following = userDoc.data().following || [];
  return following.includes(targetUserId);
};

export const followUser = async (currentUserId, targetUserId) => {
  const currentUserRef = doc(db, 'users', currentUserId);
  const targetUserRef = doc(db, 'users', targetUserId);

  await updateDoc(currentUserRef, {
    following: arrayUnion(targetUserId)
  });

  await updateDoc(targetUserRef, {
    followers: arrayUnion(currentUserId)
  });
};

export const unfollowUser = async (currentUserId, targetUserId) => {
  const currentUserRef = doc(db, 'users', currentUserId);
  const targetUserRef = doc(db, 'users', targetUserId);

  await updateDoc(currentUserRef, {
    following: arrayRemove(targetUserId)
  });

  await updateDoc(targetUserRef, {
    followers: arrayRemove(currentUserId)
  });
};

export const getFollowingUsers = async (userId, currentUserId) => {
  const userRef = doc(db, 'users', userId);
  const currentUserRef = doc(db, 'users', currentUserId);
  const [userDoc, currentUserDoc] = await Promise.all([
    getDoc(userRef),
    getDoc(currentUserRef)
  ]);

  const followingIds = userDoc.data().following || [];
  const currentUserFollowing = currentUserDoc.data().following || [];

  const followingUsers = await Promise.all(
    followingIds.map(async (id) => {
      const userDoc = await getDoc(doc(db, 'users', id));
      return {
        id: userDoc.id,
        ...userDoc.data(),
        isFollowing: currentUserFollowing.includes(id)
      };
    })
  );

  return followingUsers;
};

export const getFollowers = async (userId, currentUserId) => {
  const userRef = doc(db, 'users', userId);
  const currentUserRef = doc(db, 'users', currentUserId);
  const [userDoc, currentUserDoc] = await Promise.all([
    getDoc(userRef),
    getDoc(currentUserRef)
  ]);

  const followerIds = userDoc.data().followers || [];
  const currentUserFollowing = currentUserDoc.data().following || [];

  const followers = await Promise.all(
    followerIds.map(async (id) => {
      const userDoc = await getDoc(doc(db, 'users', id));
      return {
        id: userDoc.id,
        ...userDoc.data(),
        isFollowing: currentUserFollowing.includes(id)
      };
    })
  );

  return followers;
};