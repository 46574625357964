import axios from 'axios';
import { auth, db } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider
} from 'firebase/auth';
import { updateDoc, doc, runTransaction } from 'firebase/firestore';
import { validatePassword } from '../utils/passwordValidation';
import { getUserByUsername, getUserProfile } from '../services/firestore';
import { svgToBase64 } from '../utils/imageUtils';

const handleAuthError = (error) => {
  switch (error.code) {
    case 'auth/invalid-email':
      return 'メールアドレスの形式が正しくありません。';
    case 'auth/email-already-in-use':
      return 'このメールアドレスは既に使用されています。別のメールアドレスを使用するか、ログインしてください。';
    case 'auth/user-disabled':
      return 'このアカウントは無効化されています。管理者にお問い合わせください。';
    case 'auth/user-not-found':
      return 'アカウントが見つかりません。メールアドレスまたはユーザーネームを確認してください。';
    case 'auth/wrong-password':
      return 'パスワードが間違っています。';
    case 'auth/too-many-requests':
      return 'ログイン試行回数が多すぎます。しばらく時間をおいてから再度お試しください。';
    case 'auth/invalid-credential':
      return 'ログインに失敗しました。メールアドレス/ユーザーネームとパスワードを確認してください。';
    case 'auth/operation-not-allowed':
      return 'このログイン方法は現在無効になっています。管理者にお問い合わせください。';
    case 'auth/timeout':
      return '処理がタイムアウトしました。ネットワーク接続を確認して再度お試しください。';
    default:
      console.error('Unhandled auth error:', error);
      return 'エラーが発生しました。もう一度お試しください。';
  }
};

export const registerUser = async (email, password, username) => {
  const passwordError = validatePassword(password);
  if (passwordError) {
    throw new Error(passwordError);
  }

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const avatarResponse = await axios.get(`https://api.dicebear.com/9.x/shapes/svg?seed=${user.uid}&scale=80`);
    const avatarSvg = avatarResponse.data;
    const avatarBase64 = svgToBase64(avatarSvg);
    const avatarUrl = `data:image/svg+xml;base64,${avatarBase64}`;

    // トランザクションを使用してプロフィールを作成
    await runTransaction(db, async (transaction) => {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await transaction.get(userRef);

      if (userDoc.exists()) {
        throw new Error('User already exists');
      }

      transaction.set(userRef, {
        email: user.email,
        username,
        displayName: username,
        avatar: avatarUrl,
        createdAt: new Date()
      });
    });

    // プロフィールが確実に作成されたことを確認
    let userProfile = null;
    for (let i = 0; i < 5; i++) {  // 最大5回試行
      userProfile = await getUserProfile(user.uid);
      if (userProfile) break;
      await new Promise(resolve => setTimeout(resolve, 1000));  // 1秒待機
    }

    if (!userProfile) {
      throw new Error('Failed to create user profile');
    }

    return { ...user, ...userProfile };
  } catch (error) {
    console.error("Error in registerUser:", error);
    // エラーが発生した場合、作成したユーザーを削除
    if (auth.currentUser) {
      await auth.currentUser.delete();
    }
    throw new Error(handleAuthError(error));
  }
};

const isEmail = (identifier) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(identifier);
};

const cleanIdentifier = (identifier) => {
  const trimmed = identifier.trim();
  if (!isEmail(trimmed) && trimmed.startsWith('@')) {
    return trimmed.slice(1).toLowerCase();
  }
  return trimmed.toLowerCase();
};

export const loginUser = async (identifier, password) => {
  try {
    const cleanedIdentifier = cleanIdentifier(identifier);
    let email;
    if (isEmail(cleanedIdentifier)) {
      email = cleanedIdentifier;
    } else {
      const user = await getUserByUsername(cleanedIdentifier);
      if (!user) {
        throw new Error('ユーザーが見つかりません。ユーザーネームを確認してください。');
      }
      email = user.email;
    }
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // メールアドレスの整合性チェック
    await checkAndUpdateEmailConsistency(user);

    return user;
  } catch (error) {
    throw new Error(handleAuthError(error));
  }
};

async function checkAndUpdateEmailConsistency(user) {
  try {
    const userProfile = await getUserProfile(user.uid);
    if (userProfile && userProfile.email !== user.email) {
      console.warn('Email mismatch detected. Updating Firestore...');
      await updateUserProfile(user.uid, { email: user.email });
      console.log('Firestore email updated successfully');
    }
  } catch (error) {
    console.error('Error checking/updating email consistency:', error);
    // ここでは整合性チェックのエラーはスローしない
    // ログイン自体は成功しているため、ユーザーエクスペリエンスを損なわないようにする
  }
}

export const logoutUser = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    throw new Error('ログアウトに失敗しました。もう一度お試しください。');
  }
};

export const updateUserThemePreference = async (userId, isDarkMode) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, { isDarkMode });
  } catch (error) {
    console.error('Error updating user theme preference:', error);
    throw error;
  }
};

export const updateUserProfile = async (userId, data) => {
  const user = auth.currentUser;
  const userRef = doc(db, 'users', userId);

  try {
    if (data.displayName) {
      await updateProfile(user, { displayName: data.displayName });
    }
    await updateDoc(userRef, data);
  } catch (error) {
    console.error("Error updating user profile", error);
    throw error;
  }
};

export const updateUserPassword = async (newPassword) => {
  const user = auth.currentUser;
  try {
    await updatePassword(user, newPassword);
  } catch (error) {
    console.error("Error updating password", error);
    throw error;
  }
};

export const reauthenticateWithPassword = async (password) => {
  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(user.email, password);

  try {
    await reauthenticateWithCredential(user, credential);
  } catch (error) {
    console.error('Re-authentication failed:', error);
    throw error;
  }
};