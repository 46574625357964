import axios from 'axios';

const TMDB_API_KEY = process.env.REACT_APP_TMDB_API_KEY;
const GOOGLE_BOOKS_API_KEY = process.env.REACT_APP_GOOGLE_BOOKS_API_KEY;
const SPOTIFY_CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const SPOTIFY_CLIENT_SECRET = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;

// TMDb API
export const searchMovies = async (query, page = 1, signal) => {
  try {
    const response = await axios.get(`https://api.themoviedb.org/3/search/movie`, {
      params: {
        api_key: TMDB_API_KEY,
        query: query,
        language: 'ja-JP',
        page: page
      },
      signal: signal
    });
    return {
      results: response.data.results.map(movie => ({
        id: movie.id,
        title: movie.title,
        releaseDate: movie.release_date,
        posterPath: movie.poster_path ? `https://image.tmdb.org/t/p/w200${movie.poster_path}` : null
      })),
      totalPages: response.data.total_pages,
      currentPage: response.data.page,
      hasMore: response.data.page < response.data.total_pages
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Movie search aborted');
      throw new Error('AbortError');
    }
    console.error('Error searching movies:', error);
    if (error.response && error.response.status === 401) {
      throw new Error('映画APIの認証に失敗しました。管理者にお問い合わせください。');
    }
    throw new Error('映画の検索中にエラーが発生しました。もう一度お試しください。');
  }
};

export const getMovieDetails = async (movieId, signal) => {
  try {
    const response = await axios.get(`https://api.themoviedb.org/3/movie/${movieId}`, {
      params: {
        api_key: TMDB_API_KEY,
        language: 'ja-JP',
      },
      signal: signal
    });
    return {
      id: response.data.id,
      title: response.data.title,
      releaseDate: response.data.release_date,
      posterPath: response.data.poster_path ? `https://image.tmdb.org/t/p/w200${response.data.poster_path}` : null
    };
  } catch (error) {
    console.error('Error fetching movie details:', error);
    throw new Error('映画の詳細取得中にエラーが発生しました。');
  }
};

// Google Books API
export const searchBooks = async (query, startIndex = 0, signal) => {
  try {
    const response = await axios.get(`https://www.googleapis.com/books/v1/volumes`, {
      params: {
        q: query,
        key: GOOGLE_BOOKS_API_KEY,
        startIndex: startIndex,
        maxResults: 10,
        fields: 'items(id,volumeInfo(title,authors,publishedDate,imageLinks/thumbnail)),totalItems'
      },
      signal: signal
    });

    if (!response.data.items) {
      return {
        results: [],
        totalItems: 0,
        startIndex: startIndex,
        hasMore: false
      };
    }

    return {
      results: response.data.items.map(book => ({
        id: book.id,
        title: book.volumeInfo.title,
        authors: book.volumeInfo.authors,
        publishedDate: book.volumeInfo.publishedDate,
        thumbnail: book.volumeInfo.imageLinks?.thumbnail
      })),
      totalItems: response.data.totalItems,
      startIndex: startIndex,
      hasMore: startIndex + response.data.items.length < response.data.totalItems
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Book search aborted');
      throw new Error('AbortError');
    }
    console.error('Error searching books:', error);
    if (error.response && error.response.status === 403) {
      throw new Error('書籍APIの利用制限に達しました。しばらく待ってから再試行してください。');
    }
    throw new Error('書籍の検索中にエラーが発生しました。もう一度お試しください。');
  }
};

export const getBookDetails = async (bookId, signal) => {
  try {
    const response = await axios.get(`https://www.googleapis.com/books/v1/volumes/${bookId}`, {
      params: {
        key: GOOGLE_BOOKS_API_KEY,
      },
      signal: signal
    });
    const book = response.data;
    return {
      id: book.id,
      title: book.volumeInfo.title,
      authors: book.volumeInfo.authors,
      publishedDate: book.volumeInfo.publishedDate,
      thumbnail: book.volumeInfo.imageLinks?.thumbnail.replace(/^http:/, 'https:')
    };
  } catch (error) {
    console.error('Error fetching book details:', error);
    throw new Error('書籍の詳細取得中にエラーが発生しました。');
  }
};

// Spotify API
let spotifyToken = null;
let tokenExpirationTime = 0;

const getSpotifyToken = async () => {
  const currentTime = Date.now();
  if (spotifyToken && tokenExpirationTime > currentTime) {
    return spotifyToken;
  }

  try {
    const response = await axios.post('https://accounts.spotify.com/api/token', 
      'grant_type=client_credentials',
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic ' + btoa(SPOTIFY_CLIENT_ID + ':' + SPOTIFY_CLIENT_SECRET)
        }
      }
    );
    spotifyToken = response.data.access_token;
    tokenExpirationTime = currentTime + (response.data.expires_in * 1000);
    return spotifyToken;
  } catch (error) {
    console.error('Error getting Spotify token:', error);
    throw new Error('音楽APIの認証に失敗しました。管理者にお問い合わせください。');
  }
};

export const searchMusic = async (query, offset = 0, signal) => {
  try {
    const token = await getSpotifyToken();
    const response = await axios.get(`https://api.spotify.com/v1/search`, {
      params: {
        q: query,
        type: 'track',
        market: 'JP',
        offset: offset,
        limit: 20
      },
      headers: {
        'Authorization': `Bearer ${token}`
      },
      signal: signal
    });
    return {
      results: response.data.tracks.items.map(track => ({
        id: track.id,
        title: track.name,
        artist: track.artists[0].name,
        album: track.album.name,
        albumArt: track.album.images[0]?.url
      })),
      total: response.data.tracks.total,
      offset: offset,
      hasMore: offset + response.data.tracks.items.length < response.data.tracks.total
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Music search aborted');
      throw new Error('AbortError');
    }
    console.error('Error searching music:', error);
    if (error.response && error.response.status === 401) {
      spotifyToken = null; // Reset token to force a new token request on next attempt
      throw new Error('音楽APIの認証に失敗しました。もう一度お試しください。');
    }
    throw new Error('音楽の検索中にエラーが発生しました。もう一度お試しください。');
  }
};

export const getMusicDetails = async (trackIds) => {
  try {
    const ids = Array.isArray(trackIds) ? trackIds : [trackIds];
    if (ids.length === 0) return [];

    const token = await getSpotifyToken();
    const response = await axios.get(`https://api.spotify.com/v1/tracks`, {
      params: { ids: ids.join(',') },
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return response.data.tracks.map(track => ({
      id: track.id,
      title: track.name,
      artist: track.artists[0].name,
      album: track.album.name,
      albumArt: track.album.images[0]?.url
    }));
  } catch (error) {
    console.error('Error fetching music details:', error);
    throw new Error('音楽の詳細取得中にエラーが発生しました。');
  }
};